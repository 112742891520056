import Axios from '../config/Axios';

export function getRequisites(nNormId) {
  return new Promise((resolve, reject) =>
    Axios.get('requisite', {
      params: {
        nNormId
      }
    })
      .then((oResponse) => {
        resolve({
          "aRequisites": oResponse.data
        });
      })
      .catch((oError) => new Response(oError.response.data).text().then((cText) => reject(cText)))
  );
}

export function getNorm(nNormId) {
  return new Promise((resolve, reject) =>
    Axios.get(`norm/${nNormId}`)
      .then((oResponse) => {
        resolve({
          "oNorm": oResponse.data
        });
      })
      .catch((oError) => new Response(oError.response.data).text().then((cText) => reject(cText)))
  );
}
