import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { NavLink, HashRouter } from 'react-router-dom';
import Axios from '../../config/Axios';
import FormModalComponent from '../../components/FormModalComponent';
import ModalComponent from '../../components/ModalComponent';
import { Form } from 'react-bootstrap';
import { AltCheckbox } from '../../components/AltCheckbox';
import SweetAlert from 'react-bootstrap-sweetalert';
import Module from './Module';
import SystemConfig from './SystemConfig';

class ModuleIndex extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      bLoading: true,
      aModItems: [],
      aAdmSysConfig: [],
      oModUpdate: {
        mod_nid: 0,
        mod_cname: '',
        mod_nvalue: 0,
        mod_bisactive: false
      },
      oSyscEdit: {
        sysc_cid: "",
        sysc_cname: "",
        sysc_cvalue: 0,
        sysc_bisactive: 0,
        sysc_cdescription: ""
      },
      rcmpAlert: null
    }
  }

  componentDidMount() {
    this.getInitData();
  };

  getInitData = () => {
    const { setAlert } = this.props;

    Promise.all([
      Axios.get('/module').then(oResponse => this.setState({ "aModItems": oResponse.data.aModItems })),
      Axios.get('/system').then(oResponse => this.setState({ "aAdmSysConfig": oResponse.data.aAdmSysConfig }))
    ])
      .then(oResponse => this.setState({ bLoading: false }))
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
      });
  }

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  }

  moduleUpdate = () => {
    this.hideAlert();
    const { setAlert } = this.props;
    const { oModUpdate } = this.state;

    Axios.put(`/module/${oModUpdate.mod_nid}`, {
      'mod_cname': oModUpdate.mod_cname,
      'mod_nvalue': oModUpdate.mod_nvalue,
      'mod_bisactive': oModUpdate.mod_bisactive
    })
      .then((oResponse) => {
        setAlert('success', 'Módulo alterado com sucesso!');
        this.getInitData();
      })
      .catch((oError) => {
        setAlert('error', "Erro ao atualizar o usuário!");
      });
  }

  handleModuleChange = (evt, cAttributeName) => {
    const { oModUpdate } = this.state;

    if (evt.target.type === 'checkbox') {
      oModUpdate[cAttributeName] = evt.target.checked;
    } else if (evt.target.value !== null) {
      oModUpdate[cAttributeName] = evt.target.value;
    }

    this.setState({
      oModUpdate
    });
  }

  onUpdate = (oModUpdate) => {
    this.setState({
      oModUpdate: oModUpdate
    });
  }

  showEditModal = (evt, oModUpdate) => {
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert customClass="modal-edit md" title="" onConfirm={this.hideAlert} showConfirm={false}>
          <div className="head-modal">
            Editar módulo
            <span role="button" tabIndex="0" onKeyPress={this.hideAlert} onClick={this.hideAlert} className="close" />
          </div>
          <Module
            bIsEditing
            nModId={oModUpdate.mod_nid}
            fnHandleClose={this.hideAlert}
            fnHandleConfirm={(cType, cMsg) => {
              this.hideAlert();
              this.getInitData();
              setAlert(cType, cMsg);
            }}
            bIsModal
          />
        </SweetAlert>
      )
    });
  }

  moduleDelete = (nModId) => {
    const { setAlert } = this.props;
    Axios.delete(`/module/${nModId}`)
      .then((oResponse) => {
        setAlert('success', 'Módulo excluído com sucesso!');
        this.getInitData();
      })
      .catch((oError) => {
        setAlert('error', 'Erro ao excluir o módulo!');
      });
    this.hideAlert();
  }

  showModuleDelete = (evt, oModDelete) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          danger
          showCancel
          confirmBtnText={"Confirma"}
          cancelBtnText={"Cancelar"}
          cancelBtnBsStyle="danger"
          title={"Remover módulo"}
          onConfirm={(evt) => this.moduleDelete(oModDelete.mod_nid)}
          onCancel={this.hideAlert}
        >
          <p>Deseja realmente excluir o módulo: {oModDelete.mod_cname}</p>
        </SweetAlert>
      )
    });
  }

  showEditSyscConfigs = (evt, oSyscItem) => {
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert customClass="modal-edit md" title="" onConfirm={this.hideAlert} showConfirm={false}>
          <div className="head-modal">
            Editar configurações básicas
            <span onKeyPress={this.hideAlert} onClick={this.hideAlert} className="close" tabIndex={0} />
          </div>
          <SystemConfig
            bIsModal
            bIsEditing
            cSystemConfigId={oSyscItem.sysc_cid}
            fnHandleClose={this.hideAlert}
            fnHandleConfirm={(cType, cMsg) => {
              this.hideAlert();
              this.getInitData();
              setAlert(cType, cMsg);
            }}
          />
        </SweetAlert>
      )
    });
  }

  systemConfigDelete = (nSyscId) => {
    const { setAlert } = this.props;
    Axios.delete(`/system/${nSyscId}`)
      .then((oResponse) => {
        setAlert('success', 'Configuração excluída com sucesso.');
        this.getInitData();
      })
      .catch((oError) => {
        setAlert('error', 'Erro ao excluir a configuração selecionada.');
      });
    this.hideAlert();
  }

  showSystemConfigseDelete = (evt, oSyscConfigDelete) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          danger
          showCancel
          confirmBtnText={"Confirma"}
          cancelBtnText={"Cancelar"}
          cancelBtnBsStyle="danger"
          title={"Remover módulo"}
          onConfirm={(evt) => this.systemConfigDelete(oSyscConfigDelete.sysc_nid)}
          onCancel={this.hideAlert}
        >
          <p>Deseja realmente excluir o item: {oSyscConfigDelete.sysc_cname}</p>
        </SweetAlert>
      )
    });
  }

  render() {
    const { aModItems, aAdmSysConfig, rcmpAlert, bLoading } = this.state;

    return (
      !bLoading && (
        <>
          {rcmpAlert}
          <section id="container" className="centered">
            <div className="basic-config">
              <>
                <div className="title-button">
                  <h1>Configurações básicas</h1>
                </div>

                <Table hover responsive striped>
                  <thead>
                    <tr>
                      <th>Nome</th>
                      <th>Descrição</th>
                      <th>Valor</th>
                      <th>Ativo</th>
                      <th>Ação</th>
                    </tr>
                  </thead>
                  <tbody>
                    {aAdmSysConfig.map((oSyscConfItem, nIndex) => (
                      <tr key={nIndex}>
                        <td>{oSyscConfItem.sysc_cname}</td>
                        <td>{oSyscConfItem.sysc_cdescription}</td>
                        <td>{oSyscConfItem.sysc_cvalue}</td>
                        <td>{oSyscConfItem.sysc_bisactive ? 'Habilitado' : 'Desabilitado'}</td>
                        <td>
                          <a className="edit-btn" title="Visualizar informações ou editar" onClick={(evt) => { this.showEditSyscConfigs(evt, oSyscConfItem) }}>
                            <i className="icon-edit" />
                          </a>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </>
            </div>

            <div className="info-modules">

              <div className="title-button title">
                <h1>Módulos</h1>
                <div className="button-stack">
                  <HashRouter>
                    <NavLink className="btn alternate" to="/Module/Create" title="Módulo">
                      Criar módulo
                    </NavLink>
                  </HashRouter>
                </div>
              </div>

              <Table hover responsive striped>
                <thead>
                  <tr>
                    <th>ID</th>
                    <th>Módulo</th>
                    <th>Valor Adicional</th>
                    <th>Ativo</th>
                    <th>Ação</th>
                  </tr>
                </thead>
                <tbody>
                  {aModItems.map((oModItem, nIndex) => (
                    <tr key={nIndex}>
                      <td>{oModItem.mod_nid}</td>
                      <td>{oModItem.mod_cname}</td>
                      <td>{oModItem.mod_nvalue}</td>
                      <td>{oModItem.mod_bisactive ? 'Habilitado' : 'Desabilitado'}</td>
                      <td>
                        <a className="edit-btn" title="Visualizar informações ou editar" onClick={(evt) => { this.showEditModal(evt, oModItem) }}>
                          <i className="icon-edit" />
                        </a>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>

          </section>
        </>
      )

    );
  }
}

export default ModuleIndex;
