import React, { Component } from 'react';
import $ from 'jquery';
import { NavLink, HashRouter } from 'react-router-dom';

class Nav extends Component {
  componentDidMount = () => {
    $(() => {
      $('body').on('click', (evt) => {
        if (evt.target.id !== 'menu-button' && evt.target.id !== 'main-menu') {
          $('#menu-button').removeClass('active');
          $('#main-menu').removeClass('opened');
        }
      });

      $('#main-menu li.has-childs > a').click(function(evt) {
        evt.stopPropagation();
        $(this).toggleClass('active');
        $(this)
          .parent()
          .find('ul')
          .stop(true, true)
          .slideToggle();
        return false;
      });
    });
  };

  render() {
    return (
      <HashRouter>
        <nav id="main-menu">
          <ul>
            <li className="top-level-item">
              <NavLink className="toggle-menu" to="/Customer" title="Clientes">
                Clientes
              </NavLink>
            </li>
            <li className="top-level-item">
              <NavLink className="toggle-menu" to="/Module" title="Módulos">
                Módulos
              </NavLink>
            </li>
            <li className="top-level-item">
              <NavLink className="toggle-menu" to="/User" title="Usuários">
                Usuários
              </NavLink>
            </li>
            <li className="top-level-item">
              <NavLink className="toggle-menu" to="/Term" title="Termos de adesão">
                Termos de adesão
              </NavLink>
            </li>
            {/* <li className="top-level-item">
              <NavLink to="/Config" title="Configurações do sistema">
                Configurações do sistema
              </NavLink>
            </li> */}
            <li className="top-level-item">
              <NavLink className="toggle-menu" to="/Norm" title="Normas">
                Normas
              </NavLink>
            </li>
            <li className="top-level-item">
              <NavLink className="toggle-menu" to="/board-models" title="Modelo de Quadros">
                Modelo de Quadros
              </NavLink>
            </li>
          </ul>
        </nav>
      </HashRouter>
    );
  }
}

export default Nav;
