import React, { Component } from 'react';
import Alert from '../../components/Alert';
import Axios from '../../config/Axios';

class RequisiteShow extends Component {
  constructor(oProps) {
    super(oProps);
    const { req_nid } = this.props;

    this.state = {
      "req_nid": req_nid,
      "oRequisite": '',
      "bLoading": true
    };
  }

  componentDidMount = () => {
    const { req_nid } = this.state;

    return Promise.all([
      this.getRequisite(req_nid)
    ]).then(() => {
      this.setState({ "bLoading": false });
    });
  };

  onClick = (evt, cKey1, cKey2) => {
    const { oRequisite } = this.state;
    oRequisite.requisite_section[cKey1].item_checklist[cKey2].ichk_bisselected = evt.target.checked;
    this.setState({ oRequisite });
  }

  getRequisite = (nRequisiteId) =>{
    Axios.get(`requisite/${nRequisiteId}`)
      .then((oResponse) => {
        this.setState({ "oRequisite": oResponse.data });
      })
      .catch(() => {
      });
  };

  hideAlert = () => {
    this.setState({
      "rcmpAlert": null
    });
  };

  msgSuccess = (cMsg) => {
    this.hideAlert();
    this.componentDidMount();
    this.setAlert('success', cMsg);
  };

  setAlert = (cType, cMsg) => {
    this.setState({
      "rcmpAlertMsg": (
        <Alert type={cType} isOpen onCloseAlert={() => {}}>
          {cMsg}
        </Alert>
      )
    });
  };

  removeRequisite = (evt, nRequisiteId) => {
    Axios.delete(`evidence/${nRequisiteId}`)
      .then(() => {
        this.setState((oPrevState) => ({
          "aEvidences": oPrevState.aEvidences.filter((oEvidence) => oEvidence.evid_nid !== nRequisiteId)
        }));
      })
      .catch((oError) => {
        this.setState({
          "rcmpAlertMsg": (
            <Alert type="error" isOpen onCloseAlert={() => {}}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  };

  render() {
    const { oRequisite, bLoading, rcmpAlert, rcmpAlertMsg } = this.state;

    return (
      !bLoading && (
        <div>
          {rcmpAlert}
          {rcmpAlertMsg}
          {oRequisite && (
            <form className="form">
              <>
                {oRequisite.requisite_section && oRequisite.requisite_section.map((oSection, cKey1) => (
                  <>
                    <label>
                      <h2>{oSection.rs_cname || ''}</h2>
                    </label>
                    <label>{oSection.rs_cdescription || ''}</label>
                    {oSection.item_checklist &&
                      oSection.item_checklist.map((oItem, cKey2) => (
                        <div key={cKey2} className="box-check">
                          <label>
                            <input
                              style={{ marginRight: '15px' }}
                              type="checkbox"
                              title="Testando"
                              className="split"
                              checked={oItem.ichk_bisselected}
                              value={oItem.ichk_bisselected}
                              label={oItem.ichk_citem}
                              onChange={(evt) => this.onClick(evt, cKey1, cKey2)}
                            />
                            <span className="fake-check" />
                            {oItem.ichk_citem}
                          </label>
                        </div>
                      ))}
                  </>
                ))}
              </>
            </form>
          )}
        </div>
      )
    );
  }
}

export default RequisiteShow;
