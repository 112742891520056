import React, { Component } from 'react';
import { Input, Button } from 'reactstrap';
import axios from 'axios';
import imgfLogo from '../../assets/img/logo.png';
import InputPassword from '../../components/InputPassword';

class Login extends Component {
  constructor(oProps) {
    super(oProps);

    document.body.classList.add('w-bkg');

    this.state = {
      "user_cemail": '',
      "user_cpassword": '',
      "bHasAlertError": false,
      "cErrorMessage": ''
    };
  }

  closeAlert = () => {
    this.setState({
      "cErrorMessage": '',
      "bHasAlertError": false
    });
  };

  handleChange = (evt) => {
    this.setState({ [evt.target.name]: evt.target.value });
  };

  handleSubmit = (evt) => {
    const { user_cemail, user_cpassword } = this.state;
    evt.preventDefault();
    axios
      .post('/login', {
        cEmail: user_cemail,
        cPassword: user_cpassword
      })
      .then((oResponse) => {
        if (oResponse.status === 200) {
          axios.defaults.headers.common.Authorization = `Bearer ${oResponse.data.cAccessToken}`;
          localStorage.setItem('cAccessToken', oResponse.data.cAccessToken);
          localStorage.setItem('nExpiresIn', oResponse.data.nExpiresIn);
          localStorage.setItem('nUserId', oResponse.data.nUserId);
          localStorage.setItem('aPermissions', JSON.stringify(oResponse.data.aPermissions));
          document.body.classList.remove('w-bkg');
          window.location.href = '/';
        }
      })
      .catch((oError) => {
        let cErrorMessage = '';
        if (oError.response.status === 422) {
          cErrorMessage = 'E-mail ou senha incorreta.';
        } else {
          cErrorMessage = 'Opa! Estamos temporariamente com problemas.';
        }

        this.setState({
          cErrorMessage,
          "bHasAlertError": true
        });
      });
  };

  render() {
    const { user_cemail, user_cpassword, bHasAlertError, cErrorMessage } = this.state;
    return (
      <section id="register">
        <div className="wrapper-login">
          <header>
            <div className="logo">
              <img src={imgfLogo} alt="Acredite.se" />
            </div>
            <h1>Login</h1>
          </header>
          <main className="form">
            <form className="form-horizontal" onSubmit={this.handleSubmit}>
              <Input
                value={user_cemail}
                onChange={this.handleChange}
                maxLength="250"
                className="form-control"
                placeholder="E-mail"
                id="user_cemail"
                required
                autoFocus=""
                name="user_cemail"
                type="email"
              />
              <InputPassword value={user_cpassword} onChange={this.handleChange} />

              <Button type="submit">Entrar</Button>
              {bHasAlertError && (
                <div className="alert error">
                  {cErrorMessage}
                  <span onClick={this.closeAlert} href="#" title="Fechar" aria-label="Fechar" className="close" />
                </div>
              )}
            </form>
          </main>
        </div>
      </section>
    );
  }
}

export default Login;
