export function validateCNPJ(cCnpj) {
  cCnpj = cCnpj.replace(/[^\d]+/g, '');

  if (cCnpj === '') return false;

  if (cCnpj.length !== 14) return false;

  // Elimina CNPJs invalidos conhecidos
  if (
    cCnpj === '00000000000000' ||
    cCnpj === '11111111111111' ||
    cCnpj === '22222222222222' ||
    cCnpj === '33333333333333' ||
    cCnpj === '44444444444444' ||
    cCnpj === '55555555555555' ||
    cCnpj === '66666666666666' ||
    cCnpj === '77777777777777' ||
    cCnpj === '88888888888888' ||
    cCnpj === '99999999999999'
  )
    return false;

  // Valida DVs
  let nSize = cCnpj.length - 2;
  let cNumbers = cCnpj.substring(0, nSize);
  const cDigits = cCnpj.substring(nSize);
  let nSum = 0;
  let nPosition = nSize - 7;
  for (let i = nSize; i >= 1; i--) {
    nSum += cNumbers.charAt(nSize - i) * nPosition--;
    if (nPosition < 2) nPosition = 9;
  }
  let nResult = nSum % 11 < 2 ? 0 : 11 - (nSum % 11);

  if (String(nResult) !== cDigits.charAt(0)) return false;

  nSize += 1;
  cNumbers = cCnpj.substring(0, nSize);
  nSum = 0;
  nPosition = nSize - 7;
  for (let i = nSize; i >= 1; i--) {
    nSum += cNumbers.charAt(nSize - i) * nPosition--;
    if (nPosition < 2) nPosition = 9;
  }
  nResult = nSum % 11 < 2 ? 0 : 11 - (nSum % 11);
  if (String(nResult) !== cDigits.charAt(1)) return false;

  return true;
}

export function validateCPF(cCpf) {
  cCpf = cCpf.replace(/[^\d]+/g, '');
  if (cCpf === '') return false;
  // Elimina CPFs invalidos conhecidos

  if (
    cCpf.length !== 11 ||
    cCpf === '00000000000' ||
    cCpf === '11111111111' ||
    cCpf === '22222222222' ||
    cCpf === '33333333333' ||
    cCpf === '44444444444' ||
    cCpf === '55555555555' ||
    cCpf === '66666666666' ||
    cCpf === '77777777777' ||
    cCpf === '88888888888' ||
    cCpf === '99999999999'
  )
    return false;

  // Valida 1o digito
  let nAdd = 0;
  for (let i = 0; i < 9; i++) nAdd += parseInt(cCpf.charAt(i)) * (10 - i);
  let nRev = 11 - (nAdd % 11);

  if (nRev === 10 || nRev === 11) nRev = 0;
  if (nRev !== parseInt(cCpf.charAt(9))) return false;

  // Valida 2o digito
  nAdd = 0;
  for (let i = 0; i < 10; i++) nAdd += parseInt(cCpf.charAt(i)) * (11 - i);
  nRev = 11 - (nAdd % 11);
  if (nRev === 10 || nRev === 11) nRev = 0;
  if (nRev !== parseInt(cCpf.charAt(10))) return false;
  return true;
}
