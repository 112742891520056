/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import Axios from '../../config/Axios';
import Norm from './Norm';
import { Table } from 'reactstrap';

class NormIndex extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      "bLoading": true,
      "aNorms": [],
      "aPages": [{ "cName": 'Norm.titulo', "cLink": undefined }]
    };
  }

  componentDidMount = () => {
    Axios.get('/norm')
      .then((oRes) => {
        this.setState({
          "aNorms": oRes.data,
          "bLoading": false
        });
      })
      .catch(() => {});
  };

  hideAlert = () => {
    this.setState({
      "rcmpAlert": null
    });
  };

  msgSuccess = (cMsg) => {
    const { setAlert } = this.props;
    this.hideAlert();
    this.componentDidMount();
    setAlert('success', cMsg);
  };

  openNorms = (evt) => {
    evt.preventDefault();
    this.setState({
      "rcmpAlert": (
        <SweetAlert
          customClass="modal-edit md"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            Nova norma
            <span role="button" tabIndex="0" onKeyPress={this.hideAlert} onClick={this.hideAlert} className="close" href="" />
          </div>
          <Norm
            onSuccess={(cMsg) => {
              this.msgSuccess(cMsg);
            }}
            closeModal={this.hideAlert}
          />
        </SweetAlert>
      )
    });
  };

  render() {
    const { bLoading, aPages, rcmpAlert, aNorms } = this.state;
    const { history } = this.props;

    return (
      <div className="centered">
        <div className="title-button">
          <h1>Normas</h1>

          <div className="button-stack">
            <button type="button" onClick={(evt) => this.openNorms(evt)} className="btn primary no-left-margin">
              Nova norma
            </button>
          </div>
        </div>

        {rcmpAlert}

        <Table hover responsive striped className="norm">
            <thead>
              <tr>
                <th>Nome</th>
                <th>Descrição</th>
                <th>Selo</th>
                <th>Requisitos</th>
              </tr>
            </thead>
            <tbody>
              {aNorms.map((oNorm, key) => (
                <tr key={oNorm.norm_nid}
                  onKeyPress={() => {
                    history.push(`/Requisite/${oNorm.norm_nid}`);
                  }}
                  onClick={() => {
                    history.push(`/Requisite/${oNorm.norm_nid}`);
                  }}
                >
                  <td>{oNorm.norm_cinitials}</td>
                  <td>{oNorm.norm_cname}</td>
                  <td>{oNorm.norm_flabelimage ? (<img className="seal-img" src={`data:image/png;base64,${oNorm.norm_flabelimage}`} alt="Selo" />) : ''}</td>
                  <td>{oNorm.requisites_count}</td>
                </tr>
              ))}
            </tbody>
          </Table>

      </div>
    );
  }
}

export default NormIndex;
