import React, { Component } from 'react';

export class RequisiteSection extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      "oSection": {
        "rs_cname": '',
        "rs_cdescription": '',
        "item_checklist": [],
        "rs_norder": 1,
        ...oProps.oSection
      }
    };
  }
  
  componentDidMount = () => {
    const aDropdowns = Array.from(document.querySelectorAll('textarea'));
    aDropdowns.forEach((elNode) => {
      const evtSimulate = { target: elNode };
      this.autoGrow(evtSimulate);
    });
  };

  handleChange = (evt) => {
    const { oSection } = this.state;
    const { onChange } = this.props;
    oSection[evt.target.name] = evt.target.value;
    this.setState({ oSection });

    onChange(oSection);
  };

  addChecklist = (evt) => {
    evt.preventDefault();

    this.setState(
      (oPrevState) => ({
        "oSection": {
          ...oPrevState.oSection,
          "item_checklist": [{ "ichk_norder": 1 }]
        }
      }),
      () => {
        const { oSection } = this.state;
        const { onChange } = this.props;
        onChange(oSection);
      }
    );
  };

  addItem = (evt, nId) => {
    evt.preventDefault();

    const { oSection } = this.state;
    const { onChange } = this.props;

    let aNewItemChecklist = [
      ...oSection.item_checklist.slice(0, nId + 1),
      { "ichk_citem": '', "ichk_nvirtualid": Math.random() },
      ...oSection.item_checklist.slice(nId + 1)
    ];
    aNewItemChecklist = aNewItemChecklist.map((oItem, nIndex) => ({ ...oItem, "ichk_norder": nIndex + 1 }));

    this.setState(
      (oPrevState) => ({
        "oSection": {
          ...oPrevState.oSection,
          "item_checklist": aNewItemChecklist
        }
      }),
      () => {
        onChange(this.state.oSection);
      }
    );
  };

  removeItem = (evt, nId) => {
    evt.preventDefault();
    const { onChange } = this.props;
    const { oSection } = this.state;

    this.setState(
      (oPrevState) => ({
        "oSection": {
          ...oPrevState.oSection,
          "item_checklist": oPrevState.oSection.item_checklist
            .filter((oItem, nIndex) => nIndex !== nId)
            .map((oItem, nIndex) => ({ ...oItem, "ichk_norder": nIndex + 1 }))
        }
      }),
      () => {
        onChange(oSection);
      }
    );
  };

  handleChangeItem = (evt, nId) => {
    const { oSection } = this.state;
    const { onChange } = this.props;

    const cValue = evt.target.type === 'checkbox' ? evt.target.checked : evt.target.value;
    oSection.item_checklist[nId][evt.target.name] = cValue;

    this.setState({ oSection });
    onChange(oSection);
  };

  autoGrow = (evt) => {
    const elGrow = evt.target;
    elGrow.style.height = '5px';
    elGrow.style.height = `${elGrow.scrollHeight}px`;
  };

  render() {
    const { oSection } = this.state;
    const { onDelete } = this.props;
    return (
      <li className="requisite-section">
        <span
          aria-labelledby="requisite-section"
          className="close"
          title="Excluir"
          role="button"
          tabIndex={0}
          onKeyPress={() => onDelete(oSection)}
          onClick={() => onDelete(oSection)}
        />

        <label className="split1">
          <input
            name="rs_cname"
            onChange={this.handleChange}
            maxLength="100"
            defaultValue={oSection.rs_cname || ''}
            type="text"
            className="form-control rs_cname"
            placeholder="Título opcional"
          />
        </label>

        <label className="split1">
          <textarea
            name="rs_cdescription"
            onKeyUp={(evt) => this.autoGrow(evt)}
            rows={1}
            onChange={this.handleChange}
            defaultValue={oSection.rs_cdescription || ''}
            type="text"
            className="form-control"
            placeholder="Descrição opcional"
          />
        </label>

        <div>
          {oSection.item_checklist.map((oItem, nId) => (
            <div className="checklist-item" key={oItem.ichk_nvirtualid}>
              <label className="checkboxEdit">
                <input
                  type="checkbox"
                  name="ichk_bisselected"
                  id={`label${oItem.ichk_nvirtualid}`}
                  defaultValue={oItem.ichk_bisselected || ''}
                  onChange={(evt) => this.handleChangeItem(evt, nId)}
                />
                <span className="check" />
              </label>

              <input
                type="text"
                name="ichk_citem"
                defaultValue={oItem.ichk_citem || ''}
                onChange={(evt) => this.handleChangeItem(evt, nId)}
                maxLength="255"
                required
                placeholder="Item"
              />

              <button className="bt remove" type="button" onClick={(evt) => this.removeItem(evt, nId)}>
                -
              </button>

              <button className="bt add" type="button" onClick={(evt) => this.addItem(evt, nId)}>
                +
              </button>
            </div>
          ))}
        </div>

        {oSection.item_checklist.length === 0 && (
          <a href="/#add" className="add-checklist" onClick={(evt) => this.addChecklist(evt)}>
            Adicionar checklist
          </a>
        )}
      </li>
    );
  }
}

export default RequisiteSection;
