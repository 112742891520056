import React, { Component } from 'react';
import { NavLink, HashRouter } from 'react-router-dom';
import SweetAlert from 'react-bootstrap-sweetalert';
import Axios from '../../config/Axios';
import * as Url from '../../config/Url';
import { Table } from 'reactstrap';

export class TermIndex extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      "aTerms": []
    };
  }

  componentDidMount = () => {
    Axios.get(Url.default.oBase.cUrl + Url.default.oTerm.cUrl)
      .then((oRes) => {
        this.setState({
          "aTerms": oRes.data
        });
      })
      .catch((oError) => {
        this.props.setAlert('error', oError.aMsgErrors);
      });
  };

  editTerm = (nAdctId) => {
    const { history } = this.props;
    history.push(`/Term/Update/${nAdctId}`);
  };

  removeTerm = (nAdctId) => {
    const { history } = this.props;
    Axios.delete(`/term/${nAdctId}`)
      .then(oResponse => {
        this.props.setAlert('success', 'Termo removido com sucesso!');
        this.hideAlert();
        this.componentDidMount();
      })
      .catch(oError => {
        this.props.setAlert('error', oError.aMsgErrors);
        this.hideAlert();
        this.componentDidMount();
      });
  };

  hideAlert = () => {
    this.setState({
      "rcmpAlert": null
    });
  };

  showDelete = (evt, nAdctId) => {
    evt.preventDefault();
    this.setState({
      "rcmpAlert": (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Confirmar"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          title="Remover"
          onConfirm={() => this.removeTerm(nAdctId)}
          onCancel={this.hideAlert}
        >
          Você tem certeza que deseja remover este termo de adesão?
        </SweetAlert>
      )
    });
  };

  renderModules() {
    const { aTerms } = this.state;

    return (
      <Table hover responsive striped>
      <thead>
        <tr>
          <th>#</th>
          <th>Título</th>
          <th>Publicado</th>
          <th>Ação</th>
        </tr>
      </thead>
      <tbody>
        {aTerms.map((oTerm, cKey) => (
          <tr key={cKey}>
            <td>{oTerm.adct_nid}</td>
            <td>{oTerm.adct_ctitle}</td>
            <td>{oTerm.adct_bispublished ? 'Sim' : 'Não'}</td>
            <td>
              <a className="rounded-btn edit pointer" title="Visualizar informações ou editar" onClick={(evt) => this.editTerm(oTerm.adct_nid)}>
                <i className="icon-edit" />
              </a>
              &nbsp;&nbsp;&nbsp;
              <a className="rounded-btn edit pointer" title="Remover" onClick={(evt) => this.showDelete(evt, oTerm.adct_nid)}>
                <i className="icon-close" />
              </a>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
    );

  }

  render() {
    const { aTerms, rcmpAlert } = this.state;

    return (
      <>
      {rcmpAlert}
      <div className="centered">
        <div className="title-button">
          <h1>Gerenciar termos de adesão</h1>
          <div className="button-stack">
            <HashRouter>
              <NavLink className="btn alternate" to="/Term/Create" title="Criar termo de adesão">
                Criar termo de adesão
              </NavLink>
            </HashRouter>
          </div>
        </div>

        {this.renderModules()}
      </div>
      </>
    );
  }
}

export default TermIndex;
