import Axios from '../config/Axios';

export default function getRefreshToken(nNormId) {
  return new Promise((resolve, reject) =>
    Axios.get('/login/oauth/token', {
      params: {
        "nAdminUserId": Number(localStorage.getItem('nUserId'))
      }
    })
      .then((oResponse) => {
        localStorage.setItem('cAccessToken', oResponse.data.cAccessToken);
        localStorage.setItem('nExpiresIn', oResponse.data.nExpiresIn);
        Axios.defaults.headers.common.Authorization = 'Bearer ' + oResponse.data.cAccessToken;
        resolve(oResponse);
      })
      .catch((oError) => {
        reject(oError);
      })
  );
}
