import React, { Component } from 'react';
import Axios from '../../config/Axios';
import { AltCheckbox } from '../../components/AltCheckbox';

class BoardModelStatus extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      bIsLoading: false
    };
  }

  handleChangeStatus = evt => {
    const bChecked = evt.target.checked;

    const { bIsLoading } = this.state;
    const { setAlert, aBoardModels, oBoardModel } = this.props;

    if (bIsLoading) return;

    // update status in the state
    const aBoardModelsUpdated = aBoardModels.map(oBoardModelItem => {
      if (oBoardModelItem.opmd_nid === oBoardModel.opmd_nid) {
        oBoardModelItem.opmd_bstatus = bChecked;
      }
      return oBoardModelItem;
    });

    this.setState({ aBoardModels: aBoardModelsUpdated });

    this.setState({
      bIsLoading: true
    });

    Axios.post(`operational-panel-model/${oBoardModel.opmd_nid}/change-status`, {
      bstatus: bChecked
    })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);

        // reset status in the state
        const aBoardModelsUpdated = aBoardModels.map(oBoardModelItem => {
          if (oBoardModelItem.opmd_nid === oBoardModel.opmd_nid) {
            oBoardModelItem.opmd_bstatus = !bChecked;
          }
          return oBoardModelItem;
        });

        this.setState({ aBoardModels: aBoardModelsUpdated });
      })
      .finally(() => {
        this.setState({
          bIsLoading: false
        });
      });
  };

  render() {
    const { bIsLoading } = this.state;
    const { oBoardModel } = this.props;
    return (
      <AltCheckbox
        name="opmd_bstatus"
        id="opmd_bstatus"
        label="Ativo"
        onChange={evt => this.handleChangeStatus(evt, oBoardModel)}
        checked={oBoardModel.opmd_bstatus || false}
        className={bIsLoading ? 'loading-cursor' : ''}
      />
    );
  }
}

export default BoardModelStatus;
