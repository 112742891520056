import React, { useState, useEffect } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';
import PageClean from '../default/PageClean';
import { getNorm } from '../../actions/ActionRequisite';
import AltCheckbox from '../../components/AltCheckbox';
import Alert from '../../components/Alert';
import Axios from '../../config/Axios';


const Norm = ({ bIsEditing, bIsModal, nNormId, onSuccess, closeModal }) => {
  const [oNorm, setONorm] = useState({});
  const [cImagePreviewUrl, setCImagePreviewUrl] = useState('');
  const [rcmpAlertMsg, setRcmpAlertMsg] = useState(null);
  const [bLoading, setBLoading] = useState(true);
  const [fSelectedFile, setFSelectedFile] = useState(null);


  useEffect(() => {
    if (bIsEditing) {
      getNorm(nNormId).then((oData) => {
        setONorm(oData.oNorm);
        setCImagePreviewUrl(oData.oNorm.norm_flabelimage ? `data:image/png;base64,${oData.oNorm.norm_flabelimage}` : '');
        setBLoading(false);
      });
    } else {
      setBLoading(false);
    }
  }, [bIsEditing, nNormId]);

  const handleChange = (evt) => {
    const oNormCopy = { ...oNorm };
    if (evt.target.type === 'checkbox') {
      oNormCopy[evt.target.name] = evt.target.checked;
      setONorm(oNormCopy);
    } else {
      oNormCopy[evt.target.name] = evt.target.value;
      setONorm(oNormCopy);
    }
  };

  const handleChangeCustom = (elSelectedOption) => {
    setONorm({ ...oNorm, "norm_nid_normmodel": elSelectedOption.value });
  };
  

  const updateNorm = (evt) => {
    evt.preventDefault();
    //const { onSuccess } = props;
  
    const fData = new FormData();
    fData.append('norm_cname', oNorm.norm_cname);
    fData.append('norm_cinitials', oNorm.norm_cinitials);
    fData.append('norm_nid_normmodel', oNorm.norm_nid_normmodel);
    fData.append('norm_bisactive', oNorm.norm_bisactive ? 1 : 0);
  
    if (fSelectedFile) {
      fData.append('norm_flabelimage', fSelectedFile, fSelectedFile.name);
    }
  
    Axios.post(`/norm/update/${oNorm.norm_nid}`, fData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        onSuccess('Norma alterada com sucesso');
      })
      .catch((oError) => {
        setRcmpAlertMsg(
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {oError.aMsgErrors}
          </Alert>
        );
      });
  };
  

  const saveNorm = (evt) => {
    evt.preventDefault();
    const fData = new FormData();
    fData.append('norm_cname', oNorm.norm_cname);
    fData.append('norm_cinitials', oNorm.norm_cinitials);
    fData.append('norm_nid_normmodel', oNorm.norm_nid_normmodel);
    fData.append('norm_bisactive', oNorm.norm_bisactive ? 1 : 0);
  
    if (fSelectedFile) {
      fData.append('norm_flabelimage', fSelectedFile, fSelectedFile.name);
    }
  
    Axios.post(`/norm`, fData, {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })
      .then(() => {
        onSuccess('Norma criada com sucesso');
      })
      .catch((oError) => {
        setRcmpAlertMsg( // Altere aqui
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            {oError.aMsgErrors}
          </Alert>
        );
      });
  };

  const fileChangedHandler = (evt) => {
    evt.preventDefault();

    const frChanged = new FileReader();
    const fFile = evt.target.files[0];
    if (fFile && (fFile.size / 1024).toFixed() > 200) {
      this.setState({
        rcmpAlertMsg: (
          <Alert type="error" isOpen onCloseAlert={() => { }}>
            Tamanho da imagem superior a 200kb
          </Alert>
        )
      });
      return;
    }

    frChanged.onloadend = () => {
      this.setState({
        fSelectedFile: fFile,
        cImagePreviewUrl: frChanged.result
      });
    };
    if (fFile) frChanged.readAsDataURL(fFile);
  };

  const triggerInputFile = () => {
    const elLink = document.getElementById('norm_seal');
    elLink.click();
  };

  const clearImage = (e) => {
    e.preventDefault();
    this.setState({
      fSelectedFile: '',
      cImagePreviewUrl: ''
    });
  };

  const renderForm = () => {
    return (
      <form className="form" onSubmit={bIsEditing ? updateNorm : saveNorm}>
       {rcmpAlertMsg}
       <label className="split1">
        Nome da norma - Sigla <span className="required">*</span>
        <input
          onChange={handleChange}
          id="norm_cinitials"
          name="norm_cinitials"
          maxLength="25"
          value={oNorm.norm_cinitials || ''}
          required=""
          type="text"
          className="form-control"
        />
      </label>

      <label className="split1 colspan">
        Descrição<span className="required">*</span>
        <input
          onChange={handleChange}
          id="norm_cname"
          name="norm_cname"
          maxLength="100"
          value={oNorm.norm_cname || ''}
          required=""
          type="text"
          className="form-control"
        />
      </label>

      <label className="split1 colspan">
        <Input
          onChange={fileChangedHandler}
          type="file"
          name="norm_seal"
          id="norm_seal"
          className="hidden norm_seal"
          data-preview="norm_seal img"
          accept=".jpg, .jpeg, .png"
        />
        <p> Imagem do selo </p>
        {cImagePreviewUrl && <img src={cImagePreviewUrl} id="avatar-preview" />}
        {!cImagePreviewUrl && (
          <button
            type="button"
            onClick={triggerInputFile}
            className="btn alternate back small-bt small btn-outline-secondary"
          >
            Carregar imagem
          </button>
        )}

        {cImagePreviewUrl && (
          <>
            <br />
            <a href="/#remover-imagem" onClick={e => clearImage(e)}>
              Remover imagem
            </a>
          </>
        )}
      </label>

      <AltCheckbox
        title="Ativo"
        label="Ativo"
        name="norm_bisactive"
        onChange={handleChange}
        className="split1"
        checked={oNorm.norm_bisactive}
      />

        <div className="button-stack">
          <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
            Cancelar
        </button>
          <button type="submit" title="Salvar" className="btn btn btn-secondary">
            Salvar
        </button>
        </div>
      </form>

    );
  }


    if (bIsModal) {
      return (
        <PageClean bLoading={bLoading ? 1 : 0}>
          {renderForm()}
        </PageClean>
      );
    }
  
    return (
      <div className="centered">
        {renderForm()}
      </div>
    );
  };
  

  Norm.propTypes = {
    bIsEditing: PropTypes.bool,
    bIsModal: PropTypes.bool,
    nNormId: PropTypes.number,
    onSuccess: PropTypes.func.isRequired,
    closeModal: PropTypes.func.isRequired,
  };
  
  Norm.defaultProps = {
    bIsEditing: false,
    bIsModal: false,
    nNormId: null,
  };
  

export default Norm;
