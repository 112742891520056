import React, { Component } from 'react';
import { Input } from 'reactstrap';
import PropTypes from 'prop-types';

export class InputPassword extends Component {
  static propTypes = {
    inputid: PropTypes.string,
    inputplaceholder: PropTypes.string,
    inputname: PropTypes.string
  };

  static defaultProps = {
    inputid: '',
    inputplaceholder: '',
    inputname: ''
  };

  constructor(oProps) {
    super(oProps);

    this.state = {
      "value": oProps.value,
      "bShowPassword": false
    };
  }

  showPasswordChange = () => {
    this.setState((oCurrentState) => ({
      "bShowPassword": !oCurrentState.bShowPassword
    }));
  };

  handleChange = (evt) => {
    const { onChange } = this.props;
    onChange(evt);
  };

  render() {
    const { inputid, inputplaceholder, inputname } = this.props;
    const { bShowPassword, value } = this.state;

    return (
      <div className="password">
        <Input
          maxLength="250"
          className="form-control"
          id={inputid !== '' ? inputid : "user_cpassword"}
          placeholder={inputplaceholder !== '' ? inputplaceholder : "Senha"}
          name={inputname !== '' ? inputname : "user_cpassword"}
          type={bShowPassword ? 'text' : 'password'}
          value={value}
          {...this.props}
          onChange={this.handleChange}
          autoComplete="new-password"
        />
        <span
          aria-hidden
          title="Mostrar/esconder senha"
          onClick={this.showPasswordChange}
          className={`btn-hover ${bShowPassword ? 'alt' : ''}`}
        />
      </div>
    );
  }
}

export default InputPassword;
