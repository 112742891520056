import React, { Component } from 'react';
import { Button, Modal, Form } from 'react-bootstrap';
import PropTypes from 'prop-types';

class FormModalComponent extends Component {
  constructor(oProps){
    super(oProps);
    this.state = {
      bShow: true
    };
  }

  static propTypes = {
    cSize: PropTypes.string
  };

  static defaultProps = {
    cSize: "lg"
  };

  handleClose = () => {
    this.setState({
      'bShow': false
    });
    this.props.onClose();
  }

  handleSubmit = () => {
    this.setState({
      'bShow': false
    });
    this.props.onConfirm();
  }

  render() {
    return (
      <>
          <Modal size={this.props.cSize} show={this.state.bShow} onHide={this.handleClose} backdrop={this.props.bBackDrop}>
            <Modal.Header closeButton>
              <Modal.Title>{this.props.cTitle}</Modal.Title>
            </Modal.Header>
            <Form validated={false} onSubmit={this.handleSubmit}>
              <Modal.Body>
                  {this.props.children}
              </Modal.Body>

              <Modal.Footer>
                <Button variant="danger" onClick={this.handleClose} type="button">
                  {this.props.cCloseTitle}
                </Button>
                <Button variant="primary" type="submit">
                  {this.props.cSaveTitle}
                </Button>
              </Modal.Footer>
            </Form>
          </Modal>
      </>
    );
  }
}

export default FormModalComponent;
