import React, { Component } from 'react';
import { Button, Form, Input } from 'reactstrap';
import { AltCheckbox } from '../../components/AltCheckbox';
import { Alert } from '../../components/Alert';
import Axios from '../../config/Axios';
import PropTypes from 'prop-types';
import PageClean from '../default/PageClean';

class Module extends Component {

  static propTypes = {
    bIsEditing: PropTypes.bool,
    bIsModal: PropTypes.bool,
    fnHandleClose: PropTypes.func,
    fnHandleConfirm: PropTypes.func,
  };

  static defaultProps = {
    bIsEditing: false,
    bIsModal: false,
    fnHandleClose: evtHandleClose => { },
    fnHandleConfirm: evtHandleConfirm => { },
  };

  constructor(oProps) {
    super(oProps);

    this.state = {
      oModule: {
        mod_cname: '',
        mod_nvalue: null,
        mod_bisactive: false
      },
      bLoading: true,
      rcmpAlertMsg: null
    }
  }

  componentDidMount() {
    const { bIsEditing, nModId, setAlert, onClose, onUpdate } = this.props;

    if (bIsEditing) {
      Axios.get(`/module/${nModId}`)
        .then((oResponse) => {
          this.setState({
            oModule: oResponse.data.oModShow,
            bLoading: false
          });
          if (onUpdate) onUpdate(oResponse.data.oModShow);
        })
        .catch((oError) => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    }

  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { oModule } = this.state;
    const { setAlert, fnHandleConfirm, bIsEditing, history } = this.props;

    if (bIsEditing) {
      Axios.put(`/module/${oModule.mod_nid}`, {
        mod_cname: oModule.mod_cname,
        mod_nvalue: oModule.mod_nvalue,
        mod_bisactive: oModule.mod_bisactive
      })
        .then(oResponse => {
          fnHandleConfirm('success', oResponse.data.success);
        })
        .catch((oError) => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    } else {
      Axios.post('/module', {
        mod_cname: oModule.mod_cname,
        mod_nvalue: oModule.mod_nvalue,
        mod_bisactive: oModule.mod_bisactive
      })
        .then(oResponse => {
          history.push({
            pathname: '/Module',
            state: { aAlertMessages: { cType: 'success', cMsg: oResponse.data.success } }
          });
        })
        .catch((oError) => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    }
  }

  handleChange = evt => {
    const { oModule } = this.state;
    if (evt.target.type === 'checkbox') {
      oModule[evt.target.name] = evt.target.checked;
    } else if (evt.target.value !== null) {
      oModule[evt.target.name] = evt.target.value;
    }

    this.setState({
      oModule
    });
  };

  renderModule = () => {
    const { oModule } = this.state;
    const { bIsEditing, fnHandleClose, history } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="form">
        <fieldset>
          <legend>Informações cadastrais</legend>
          <label className="split2">
            Nome <span className="required">*</span>
            <Input
              required
              name="mod_cname"
              maxLength={100}
              onChange={this.handleChange}
              value={oModule.mod_cname || ''}
            />
          </label>
          <label className="split2">
            Valor <span className="required">*</span>
            <Input
              required
              name="mod_nvalue"
              type="number"
              step="0.01"
              onChange={this.handleChange}
              value={oModule.mod_nvalue || ''}
            />
          </label>
          <AltCheckbox
            name="mod_bisactive"
            id="mod_bisactive"
            label="Módulo Ativo"
            onChange={this.handleChange}
            checked={oModule.mod_bisactive || false}
          />
        </fieldset>

        <div className="button-stack">
          <button type="button" className="btn alternate back btn btn-outline-secondary" title="Cancelar" onClick={(evtClick) => {
            if (bIsEditing) {
              fnHandleClose();
            } else {
              history.push({
                pathname: '/Module'
              });
            }
          }}>
            Cancelar
            </button>
          {bIsEditing ? (
            <button type="submit" value="salvar" className="btn btn btn-secondary" title="Salvar">
              Salvar
            </button>
          ) : (
            <button type="submit" value="salvar" className="btn btn btn-secondary" title="Criar">
              Criar
            </button>
          )}
        </div>

      </Form>
    );
  }

  render() {
    const { bLoading, rcmpAlertMsg } = this.state;
    const { bIsModal } = this.props;

    if (bIsModal) {
      return (
        <PageClean bLoading={bLoading ? 1 : 0}>
          {rcmpAlertMsg}
          {this.renderModule()}
        </PageClean>
      );
    }

    return (
      <main id="container" className="centered">
        <div className="clearfix" />
        <h1>Criar módulo</h1>
        {rcmpAlertMsg}
        {this.renderModule()}
      </main>
    );
  }
}

export default Module;
