import React, { Component } from 'react';
import Axios from '../../config/Axios';
import { Button, Form, Input } from 'reactstrap';
import { Alert } from '../../components/Alert';
import PropTypes from 'prop-types';
import PageClean from '../default/PageClean';

class SystemConfig extends Component {

  static propTypes = {
    bIsEditing: PropTypes.bool,
    bIsModal: PropTypes.bool,
    cSystemConfigId: PropTypes.string,
    fnHandleClose: PropTypes.func,
    fnHandleConfirm: PropTypes.func
  };

  static defaultProps = {
    bIsEditing: false,
    bIsModal: false,
    cSystemConfigId: null,
    fnHandleClose: evtHandleClose => { },
    fnHandleConfirm: (cType, cMsg) => { },
  };

  constructor(oProps) {
    super(oProps);

    this.state = {
      oSystemConfig: {},
      bLoading: true,
      rcmpAlertMsg: null
    }
  }

  componentDidMount() {
    const { bIsEditing, cSystemConfigId } = this.props;

    if (bIsEditing) {
      Axios.get(`/system/${cSystemConfigId}`)
        .then(oResponse => {
          this.setState({
            oSystemConfig: oResponse.data.oAdminSystemConfig,
            bLoading: false
          });
        })
        .catch(oError => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    }
  }

  handleChange = evt => {
    const { oSystemConfig } = this.state;

    oSystemConfig[evt.target.name] = evt.target.value;

    this.setState({
      oSystemConfig
    });
  };

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { oSystemConfig } = this.state;
    const { fnHandleConfirm, bIsEditing } = this.props;

    if (bIsEditing) {
      Axios.put(`/system/${oSystemConfig.sysc_cid}`, {
        sysc_cname: oSystemConfig.sysc_cname,
        sysc_cvalue: oSystemConfig.sysc_cvalue,
        sysc_cdescription: oSystemConfig.sysc_cdescription
      })
        .then((oResponse) => {
          fnHandleConfirm('success', 'Configuração alterada com sucesso!');
        })
        .catch((oError) => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    }
  }

  renderForm = () => {
    const { oSystemConfig } = this.state;
    const { bIsEditing, fnHandleClose } = this.props;

    return (
      <Form onSubmit={this.handleSubmit} className="form">
        <label className="split1">
          Nome <span className="required">*</span>
          <Input
            required
            name="sysc_cname"
            maxLength={50}
            onChange={this.handleChange}
            value={oSystemConfig.sysc_cname || ''}
          />
        </label>
        <label className="split1">
          Valor <span className="required">*</span>
          <Input
            required
            name="sysc_cvalue"
            type="text"
            maxLength={50}
            onChange={this.handleChange}
            value={oSystemConfig.sysc_cvalue || ''}
          />
        </label>
        <label className="split1">
          Descrição <span className="required">*</span>
          <Input
            required
            name="sysc_cdescription"
            type="textarea"
            rows="3"
            onChange={this.handleChange}
            value={oSystemConfig.sysc_cdescription || ''}
          />
        </label>


        <div className="button-stack">

          <button type="reset" value="cancelar" className="btn alternate back btn btn-outline-secondary" title="Cancelar" onClick={fnHandleClose}>
            Cancelar
          </button>

          {bIsEditing ? (
            <button type="submit" value="salvar" className="btn btn btn-secondary" title="Salvar">
              Salvar
            </button>
          ) : (
            <button type="submit" value="criar" className="btn btn btn-secondary" title="Criar">
              Criar
            </button>
          )}
        </div>

      </Form>
    );
  }

  render() {
    const { bLoading, oSystemConfig, rcmpAlertMsg } = this.state;
    const { bIsEditing, bIsModal } = this.props;

    if (bIsModal) {
      return (
        <PageClean bLoading={bLoading ? 1 : 0}>
          {rcmpAlertMsg}
          {this.renderForm()}
        </PageClean>
      );
    }

    return (
      <div className="centered" >
        <h1>Criar Configuração Básica</h1>
        {rcmpAlertMsg}
        {this.renderForm()}
      </div>
    );
  }
}

export default SystemConfig;
