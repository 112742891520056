import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Component } from 'react';
import './App.scss';
import Header from './pages/default/Header';
import Nav from './pages/default/Nav';
import RouterExternal from './pages/default/RouterExternal';
import RouterMain from './pages/default/RouterMain';
import ReactDOM from 'react-dom';
import getRefreshToken from './actions/ActionRefreshToken'

class App extends Component {
  bIsToRefresh = false;
  bIsStarting = true;
  nRefreshingTime = 120000;

  handleTimeOut = (bIsToRefresh, nTime) => {
    setTimeout(() => {
      this.bIsToRefresh = bIsToRefresh;
    }, nTime);
  };

  handleClick = (evt) => {
    let nExpiresIn = Number(localStorage.getItem('nExpiresIn'));
    let nCurrentDate = Math.floor(new Date() / 1000);
    if(nExpiresIn < nCurrentDate) {
      localStorage.removeItem('cAccessToken');
      localStorage.removeItem('nExpiresIn');
      ReactDOM.unmountComponentAtNode(document.getElementById('root'));
      ReactDOM.render(<App />, document.getElementById('root'));
    } 
    else if(this.bIsStarting) {
      this.bIsStarting = false;
      this.handleTimeOut(true, this.nRefreshingTime);
    } else if(this.bIsToRefresh) {
      this.bIsToRefresh = false;
      getRefreshToken().then(oResponse => {
        this.handleTimeOut(true, this.nRefreshingTime);
      }).catch(oError => {});
      this.bIsToRefresh = false;
    }
  }

  render() {
    const bIsLoggedIn = localStorage.getItem('cAccessToken');

    if (bIsLoggedIn) {
      return (
        <div
          onClick={this.handleClick}
        >
          <Header />
          <Nav />
          <main id="container">
            <div className="MessageHolder centered" />
            <RouterMain />
          </main>
        </div>
      );
    }
    return <RouterExternal />;
  }
}

export default App;
