const url = {
  "oBase": {
    "cUrl": `${process.env.REACT_APP_API_BASE_URL}api/admin/`
  },
  "oCustomers": {
    "cCreate": 'customer/create',
    "cList": 'customer/list',
    "cDetails": 'customer/detail',
    "cEdit": 'customer/edit'
  },
  "oModule": {
    "cUrl": 'module'
  },
  "oTerm": {
    "cUrl": 'term'
  }
};

export default url;
