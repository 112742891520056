import React, { Component } from 'react';
import Select, { createFilter } from 'react-select';
import { Alert } from '../../components/Alert';
import Axios from '../../config/Axios';
import { RequisiteSection } from './RequisiteSection';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import PropTypes from 'prop-types';
import PageClean from '../default/PageClean';

class Requisite extends Component {
  static propTypes = {
    bIsEditing: PropTypes.bool,
    bIsModal: PropTypes.bool,
  };

  static defaultProps = {
    bIsEditing: false,
    bIsModal: false,
  };

  constructor(oProps) {
    super(oProps);

    this.state = {
      bLoading: true,
      oRequisite: {},
      aRequisites: [],
      oFilterOptionST: [
        {
          label: "Sim",
          value: true
        },
        {
          label: "Não",
          value: false
        }
      ],
    };
  }

  componentDidMount() {
    const { req_nid } = this.props;
    return Promise.all([this.getRequisite(req_nid), this.getRequisites()]).then(() => {
      const { oRequisite, aRequisites } = this.state;
      const oInitialParentRequisite = aRequisites.filter((oFiltered) => Number(oFiltered.value) === Number(oRequisite.req_nid_parent));

      this.setState({ bLoading: false, oInitialParentRequisite });
    });
  }

  handleChange = (evt) => {
    const { oRequisite } = this.state;
    oRequisite[evt.target.name] = evt.target.value;
    this.setState({ oRequisite });
  };

  saveRequisite = (evt) => {
    evt.preventDefault();
    const { oRequisite, editorState } = this.state;
    const { onSuccess, norm_nid } = this.props;
    Axios.post('requisite', { ...oRequisite, req_cdescription: editorState.getData(), norm_nid })
      .then(() => onSuccess('Requisito criado com sucesso'))
      .catch((oError) =>
        this.setState({
          "rcmpAlertMsg": (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.aMsgErrors}
            </Alert>
          )
        })
      );
  };

  updateRequisite = (evt) => {
    evt.preventDefault();
    const { oRequisite, editorState } = this.state;
    const { onSuccess } = this.props;
    Axios.put(`requisite/${oRequisite.req_nid}`, { ...oRequisite, req_cdescription: editorState.getData() })
      .then(() => onSuccess('Requisito atualizado com sucesso'))
      .catch((oError) =>
        this.setState({
          "rcmpAlertMsg": (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.aMsgErrors}
            </Alert>
          )
        })
      );
  };

  handleChangeCustom = (elSelectedOption, type = undefined) => {
    this.setState((oPrevState) => ({
      oRequisite: {
        ...oPrevState.oRequisite,
        [type]: elSelectedOption ? elSelectedOption.value : null
      }
    }));
  };

  getRequisite = (nRequisiteId) => {
    if (!nRequisiteId) {
      return Promise.resolve();
    }

    return Axios.get(`requisite/${nRequisiteId}`)
      .then((oResponse) => {
        this.setState({ oRequisite: oResponse.data[0] });
      })
      .catch(() => {
      });
  };

  getRequisites = () => {
    const { aRequisites } = this.state;
    const { norm_nid, req_nid } = this.props;

    return Axios.get(`requisite/pending/${norm_nid}`)
      .then((oResponse) => {
        Object.keys(oResponse.data).forEach((cKey) => {
          if (oResponse.data[cKey].req_nid !== req_nid) {
            aRequisites.push({
              "label": oResponse.data[cKey].req_cname,
              "value": Number(oResponse.data[cKey].req_nid),
              "bIsDisabled": oResponse.data[cKey].bIsDisabled
            });
          }
        });

        this.setState({
          aRequisites
        });
      })
      .catch((oError) => {
        this.setState({
          "rcmpAlertMsg": (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  };

  addSection = () => {
    this.setState((oPrevState) => ({
      "oRequisite": {
        ...oPrevState.oRequisite,
        "requisite_section": oPrevState.oRequisite.requisite_section.concat({
          "rs_cname": '',
          "rs_cdescription": '',
          "item_checklist": [],
          "rs_norder": oPrevState.oRequisite.requisite_section.length + 1
        })
      }
    }));
  };

  onChangeSection = (nId, oSection) => {
    const { oRequisite } = this.state;
    oRequisite.requisite_section[nId] = oSection;

    this.setState({
      oRequisite
    });
  };

  removerSection = (nId) => {
    this.setState((oPrevState) => ({
      "oRequisite": {
        ...oPrevState.oRequisite,
        "requisite_section": oPrevState.oRequisite.requisite_section
          .filter((oItem, nIndex) => nIndex !== nId)
          .map((oSection, nIndex) => ({ ...oSection, "nOrder": nIndex + 1 }))
      }
    }));
  };

  renderForm = () => {
    const { oRequisite, rcmpAlertMsg, aRequisites, oInitialParentRequisite, oFilterOptionST } = this.state;
    const { closeModal } = this.props;
    const cStringify = (elOption) => elOption.label;
    const oFilterOption = createFilter({ "bIgnoreCase": false, cStringify });

    return (
      <form className="form" onSubmit={(evt) => {
        oRequisite.req_nid ? this.updateRequisite(evt) : this.saveRequisite(evt)
      }}>
        <div className="body-modal">
          {rcmpAlertMsg}
          <label className={`split colspan`}>
            Título do requisito  <span className="required">*</span>
            <input
              onChange={this.handleChange}
              name="req_cname"
              maxLength="100"
              value={oRequisite.req_cname || ''}
              required
              type="text"
              className="form-control"
            />
          </label>

          {aRequisites.length !== 0 && (
            <label className="split2 colspan">
              Requisito pai

              <Select
                isClearable
                className="selectCustom"
                classNamePrefix="react-select"
                noOptionsMessage={() => 'Requisito não encontrado'}
                placeholder="Selecione"
                options={aRequisites}
                maxMenuHeight={190}
                filterOption={oFilterOption}
                isDisabled={oRequisite.req_etitletype === 'T'}
                defaultValue={oInitialParentRequisite}
                onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'req_nid_parent')}
              />
            </label>
          )}

          <label className="split2 colspan">
            Possui filhos
            <span className="required">*</span>
            <Select
              isDisabled={oRequisite.children_requisites_count && oRequisite.children_requisites_count > 0}
              className="selectCustom"
              isSearchable={false}
              classNamePrefix="react-select"
              placeholder="Selecione"
              options={(oRequisite.children_requisites_count && oRequisite.children_requisites_count > 0) ? [{ label: "Sim", value: true }] : oFilterOptionST}
              noOptionsMessage={() => 'Sem opções'}
              defaultValue={(oRequisite.req_etitletype && oRequisite.req_nid)
                ? { label: "Sim", value: true }
                : { label: oRequisite.req_nid ? "Não" : '', value: oRequisite.req_nid ? false : '' }}
              onChange={elSelectedOption => this.handleChangeCustom(elSelectedOption, 'req_etitletype')}
            />
          </label>


          <div id="section-desc">
            <p>Descrição</p>
            <article id="editor-view" className="document-editor__editable-container requisite-editor" />
            <div className="document-editor__toolbar requisite-editor" />

            <CKEditor
              editor={DecoupledEditor}
              config={{
                commentsOnly: true,
                toolbar: [
                  'FontFamily',
                  'FontSize',
                  '|',
                  'bold',
                  'italic',
                  'Underline',
                  'Strikethrough',
                  '|',
                  'bulletedList',
                  'numberedList',
                  'blockQuote',
                  'Alignment'
                ],
                removePlugins: ['Highlight', 'ImageResize', 'TableToolbar', 'MediaEmbed', 'Table']
              }}
              onInit={editor => {
                this.setState({ editorState: editor });

                editor.setData(oRequisite.req_cdescription || '<p></p>');

                document.querySelector('.document-editor__toolbar').appendChild(editor.ui.view.toolbar.element);
                document
                  .querySelector('.document-editor__editable-container')
                  .appendChild(editor.ui.view.editable.element);
              }}
            />
          </div>
        </div>

        <div className="button-stack">
          <button type="button" onClick={() => closeModal()} className="btn alternate back btn btn-outline-secondary">
            Cancelar
          </button>
          <button type="submit" title="Salvar" className="btn btn btn-secondary">
            Salvar
          </button>
        </div>

      </form>
    );
  }

  render() {
    const { bLoading } = this.state;
    const { bIsModal } = this.props;

    if (bIsModal) {
      return (
        <PageClean bLoading={bLoading ? 1 : 0}>
          {this.renderForm()}
        </PageClean>
      );
    }

    return (
      <div>
        {this.renderForm()}
      </div>
    );
  }
}

export default Requisite;
