/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { getNorm, getRequisites } from '../../actions/ActionRequisite';
import { OptionMenu } from '../../components/OptionMenu';
import Axios from '../../config/Axios';
import Norm from '../norm/Norm';
import Requisite from './Requisite';
import RequisiteShow from './RequisiteShow';
import moment from 'moment';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import { SortableContainer, SortableElement, sortableHandle } from 'react-sortable-hoc';
import arrayMove from 'array-move';
import $ from 'jquery';

const DragHandle = sortableHandle(props => (
  <b style={{ visibility: props.visibility ? 'visible' : 'hidden' }} className="dragComp">
    <i className="icon icon-enlarge" />
  </b>
));

const SortableItem = SortableElement(props => props.children);
const SortableList = SortableContainer(props => props.children);
export function textSearchFormat(text) {
  return text
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
}

class RequisiteIndex extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      "bLoading": true,
      "bActualMenuFolder": false,
      "nNormId": this.props.match.params.nNormId,
      "aPages": [{ "cName": 'Norm.titulo', "cLink": '/standards' }],
      "aRequisites": [],
      "aFiltredRequisites": [],
      "oNorm": []
    };
    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidUpdate = () => {
    this.applyMouseenter();
  };

  componentDidMount = () => {
    const { nNormId } = this.state;
    const { setAlert } = this.props;
    document.addEventListener('mousedown', this.handleClickOutside);
    
    Promise.all([
      getRequisites(nNormId).then((oData) => {
        this.setState({
          ...oData
        });
      }),
      getNorm(nNormId).then((oData) => {
        this.setState({
          ...oData
        });
      })
    ])
      .then(() => {
        this.setState({
          "bLoading": false
        });
      })
      .catch((oError) => {
        this.setState({ "bLoading": false });
        setAlert('error', oError.aMsgErrors);
      });
  };

  setWrapperRef(cNode) {
    this.wrapperRef = cNode;
  }

  deleteNorm = (nNormId) => {
    const { clearAlert, history } = this.props;
    Axios.delete(`norm/${nNormId}`)
      .then(() => {
        clearAlert();
        this.setState({
          "rcmpAlert": (
            <SweetAlert
              success
              title="Removida!"
              onConfirm={() => {
                history.push(`/standards`);
              }}
            >
              Norma removida com sucesso
            </SweetAlert>
          )
        });
      })
      .catch((oError) => {
        this.setState({
          "rcmpAlert": (
            <SweetAlert error title="Ops!" onConfirm={this.hideAlert}>
              Não foi possível realizar sua solicitação
              <p>{oError.aMsgErrors}</p>
            </SweetAlert>
          )
        });
      });
  };

  hideAlert = () => {
    this.setState({
      "rcmpAlert": null
    });
  };

  showDeleteNorm = (evt, nNormId) => {
    evt.preventDefault();

    this.setState({
      "rcmpAlert": (
        <SweetAlert
          showCancel
          confirmBtnText="Confirmar"
          cancelBtnText="Cancelar"
          cancelBtnBsStyle="danger"
          title="Deseja excluir a norma"
          onConfirm={() => this.deleteNorm(nNormId)}
          onCancel={this.hideAlert}
        >
          tem certeza que deseja excluir esta norma ?
        </SweetAlert>
      )
    });
  };

  showDeleteRequisite = (evt, oRequisite) => {
    evt.preventDefault();

    this.setState({
      "rcmpAlert": (
        <SweetAlert
          showCancel
          confirmBtnText="Confirmar"
          cancelBtnText="cancelar"
          cancelBtnBsStyle="danger"
          title="Deseja excluir o requisito"
          onConfirm={() => this.deleteRequisite(oRequisite.req_nid)}
          onCancel={this.hideAlert}
        >
          tem certeza que deseja excluir o requisito {oRequisite.req_cname} ?
        </SweetAlert>
      )
    });
  };

  deleteRequisite = (nRequisiteId) => {
    const { clearAlert } = this.props;
    Axios.delete(`requisite/${nRequisiteId}`)
      .then(() => {
        clearAlert();
        this.setState({
          "rcmpAlert": (
            <SweetAlert
              success
              title="Removido!"
              onConfirm={() => {
                this.componentDidMount();
                this.hideAlert();
              }}
            >
              Requisito removido com sucesso
            </SweetAlert>
          )
        });
      })
      .catch((oError) => {
        this.setState({
          "rcmpAlert": (
            <SweetAlert error title="Ops!" onConfirm={this.hideAlert}>
              Falhou
              <p>{oError.aMsgErrors}</p>
            </SweetAlert>
          )
        });
      });
  };

  menuOpen = () => {
    this.setState((oCurrentState) => ({
      "bActualMenuFolder": !oCurrentState.bActualMenuFolder
    }));
  };

  applyMouseenter = () => {
    $('.body-table li')
      .on('mouseover', function(evt) {
        $(this)
          .addClass('enter')
          .parents()
          .removeClass('enter');
        evt.stopPropagation();
      })
      .on('mouseout', function() {
        $(this).removeClass('enter');
      });
  };

  onSortEnd = ({ oldIndex, newIndex, nodes }) => {
    this.setState(
      ({ aRequisites }) => ({
        aRequisites: arrayMove(aRequisites, oldIndex, newIndex)
      }),
      () => {
        const { aRequisites } = this.state;
        let aNewIndexs = aRequisites.map((oReq, index) => {
          return { req_nid: Number(oReq.req_nid), nNewIndex: index };
        });
        Axios.post(`/requisite/change-order`, aNewIndexs);
      }
    );
  };

  findById = (data, oldIndex, newIndex, oReq) => {
    for (var i = 0; i < data.length; i++) {
      if (data[i].req_nid === oReq.req_nid) {
        data[i].recursive_children_requisite = arrayMove(oReq.recursive_children_requisite, oldIndex, newIndex);
      } else if (data[i].recursive_children_requisite.length) {
        this.findById(data[i].recursive_children_requisite, oldIndex, newIndex, oReq);
      }
    }
    return data;
  };

  onSortEndChilds = (oReq, { oldIndex, newIndex, nodes }) => {
    const { aRequisites } = this.state;
    this.setState({ aRequisites: this.findById(aRequisites, oldIndex, newIndex, oReq) }, () => {
      let aNewIndexs = oReq.recursive_children_requisite.map((oReq, index) => {
        return { req_nid: Number(oReq.req_nid), nNewIndex: index };
      });

      Axios.post(`/requisite/change-order`, aNewIndexs);
    });
  };

  renderChilds = oRequisite => {
    const { aFiltredRequisites } = this.state;
    return (
      <>
        {oRequisite.recursive_children_requisite && Object.keys(oRequisite.recursive_children_requisite).length > 0 && (
          <div className={`subLevel ${oRequisite.open ? 'open' : ''}`}>
            <SortableList
              useDragHandle
              helperClass="tab"
              transitionDuration={0}
              helperContainer={this.containerEl}
              distance={10}
              onSortEnd={this.onSortEndChilds.bind(this, oRequisite)}
            >
              <ul ref={this[`${oRequisite.req_nid}_ref`]}>
                {Object.keys(oRequisite.recursive_children_requisite).map((cKey, i) => (
                  <SortableItem
                    value={oRequisite.recursive_children_requisite[cKey].req_nid}
                    sortKey={oRequisite.recursive_children_requisite[cKey].req_nid}
                    key={`drag2ItemF-${i}-${oRequisite.recursive_children_requisite[cKey].req_nid}`}
                    index={i}
                  >
                    <li
                      itemID={oRequisite.recursive_children_requisite[cKey].req_nid}
                      key={`req${oRequisite.recursive_children_requisite[cKey].req_nid}`}
                      className={`${oRequisite.open ? 'open' : ''}`}
                      role="button"
                      tabIndex={i}
                      onKeyPress={evt => {
                        evt.stopPropagation();
                        this.loadChild(evt, oRequisite.recursive_children_requisite[cKey]);
                      }}
                      onClick={evt => {
                        evt.stopPropagation();
                        this.loadChild(evt, oRequisite.recursive_children_requisite[cKey]);
                      }}
                    >
                      <DragHandle visibility={aFiltredRequisites.length === 0} />

                      {this.renderRequisiteTitleDescription(oRequisite.recursive_children_requisite[cKey])}

                      <div className="optionsContainerTable">
                          <span className="optionsHover mobile480-hide">{this.renderRequisiteMenu(oRequisite.recursive_children_requisite[cKey])}</span>
                      </div>
                      {this.renderChilds(oRequisite.recursive_children_requisite[cKey])}
                    </li>
                  </SortableItem>
                ))}
              </ul>
            </SortableList>
          </div>
        )}
      </>
    );
  };

  loadChild = evt => {
    this.elNodeLi = evt.target;

    if (evt.target.classList.contains('insidespan') === false) {
      if (evt.target.closest('li').classList.contains('open')) {
        evt.target.closest('li').classList.toggle('open');
        if (evt.target.closest('li').getElementsByClassName('subLevel')[0]) {
          evt.target
            .closest('li')
            .getElementsByClassName('subLevel')[0]
            .classList.toggle('open');
        }
      } else if (evt.target.closest('li').getElementsByClassName('subLevel')[0]) {
        this.elNodeLi.closest('li').classList.toggle('open');
        const elHoShow = evt.target.closest('li').getElementsByClassName('subLevel')[0];
        elHoShow.classList.toggle('open');
      }
    }
  };

  renderRequisiteTitleDescription = oRequisite => (
    <span>
      <div className="requisite-item-list-title">
        <div>
          {oRequisite.children_requisites_count > 0 && (
            <span>
              <i className="icon icon-next" />
            </span>
          )}
          &nbsp;
        </div>
        <div className={this.requisiteDescription(oRequisite.requisite_section) === '' ? 'inc-width' : ''}>
          {oRequisite.req_cname}
        </div>
        {this.requisiteDescription(oRequisite.requisite_section) !== '' && (
          <div className="requisite-item-list-description">
            {this.requisiteDescription(oRequisite.requisite_section)}
          </div>
        )}
      </div>
    </span>
  );

  requisiteDescription = aSections => {
    const oSectionDescription = aSections.find(oSection => oSection.rs_cdescription);
    return oSectionDescription ? oSectionDescription.rs_cdescription : '';
  };

  openContextMenu = e => {
    if (e.target.closest('div').getElementsByClassName('changeState')[0]) {
      e.target.closest('div').getElementsByClassName('changeState')[0].style = 'display: block';
    }
  };

  renderContextMenu = oRequisite => {
    return (
      <OutsideHandlerClick handleClickOutside={this.closeContextMenu}>
        <div className="changeState" id="changeState">
          {oRequisite.stat_nid !== 25 && (
            <a onClick={evt => this.changeStatus(evt, oRequisite.req_nid, 25)} className="finished" href="#">
              Concluído
            </a>
          )}
          {oRequisite.stat_nid !== 27 && (
            <a onClick={evt => this.changeStatus(evt, oRequisite.req_nid, 27)} className="pending" href="#">
              Pendente
            </a>
          )}
          {oRequisite.stat_nid !== 26 && (
            <a onClick={evt => this.changeStatus(evt, oRequisite.req_nid, 26)} className="n-applied" href="#">
              Não se aplica
            </a>
          )}
        </div>
      </OutsideHandlerClick>
    );
  };

  renderRequisiteMenu = oRequisite => (
    <>
      <a
        title="Remover"
        className="insidespan"
        onClick={evt => {
          evt.stopPropagation();
          this.showDeleteRequisite(evt, oRequisite);
        }}
      >
        <i className="icon icon-bin" />
      </a>

      <a
        title="Editar"
        className="insidespan"
        onClick={evt => {
          evt.stopPropagation();
          this.openRequisite(evt, oRequisite);
        }}
      >
        <i className="icon icon-edit" />
      </a>
    </>
  );

  openRequisite = (evt, oRequisite) => {
    const { setAlert } = this.props;
    const { oNorm } = this.state;

    if (!evt.target.className.includes('insidespan') && !evt.target.className.includes('btn-menu')) {
      this.setState({
        "rcmpAlert": (
          <SweetAlert customClass="modal-edit md" title="" onConfirm={this.hideAlert} showConfirm={false}>
            <div className="head-modal">
              {oRequisite.req_cname}
              <span role="button" tabIndex="0" onKeyPress={this.hideAlert} onClick={this.hideAlert} className="close" />
            </div>
            <Requisite
              norm_nid={oNorm.norm_nid}
              req_nid={oRequisite.req_nid}
              bIsModal
              bIsEditing
              onSuccess={(cMsg) => {
                this.msgSuccess(cMsg);
                this.componentDidMount();
              }}
              closeModal={this.hideAlert}
            />
          </SweetAlert>
        )
      });
    }
  };

  msgSuccess = (cMsg) => {
    const { setAlert } = this.props;
    this.hideAlert();
    this.componentDidMount();

    const aDropdowns = Array.from(document.querySelectorAll('.open'));
    aDropdowns.forEach((elNode) => {
      elNode.classList.remove('open');
    });

    setAlert('success', cMsg);
  };

  openRequisites = (evt) => {
    evt.preventDefault();
    const { oNorm } = this.state;

    this.setState({
      "rcmpAlert": (
        <SweetAlert customClass="modal-edit md" title="" onConfirm={this.hideAlert} showConfirm={false}>
          <div className="head-modal">
            Novo requisito - {oNorm.norm_cinitials}
            <span role="button" tabIndex="0" onKeyPress={this.hideAlert} onClick={this.hideAlert} className="close" href="" />
          </div>
          <Requisite
            norm_nid={oNorm.norm_nid}
            bIsModal
            onSuccess={(cMsg) => {
              this.msgSuccess(cMsg);
              this.componentDidMount();
            }}
            closeModal={this.hideAlert}
          />
        </SweetAlert>
      )
    });
  };

  changeStatus = (evt, nRequisiteId, cSlugname) => {
    evt.preventDefault();

    const { setAlert } = this.props;

    Axios.post('/requisite/change-status', {
      nRequisiteId,
      cSlugname
    })
      .then(() => {
        this.msgSuccess('Status alterado com sucesso');
      })
      .catch((oError) => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  openNormEdit = (evt, oNorm) => {
    evt.preventDefault();
    this.setState({
      "rcmpAlert": (
        <SweetAlert
          customClass="modal-edit md disabled-overflow"
          title=""
          onConfirm={this.hideAlert}
          showConfirm={false}
        >
          <div className="head-modal">
            Editar {oNorm.norm_cname}
            <span role="button" tabIndex="0" onKeyPress={this.hideAlert} onClick={this.hideAlert} className="close" href="" />
          </div>
          <Norm
            bIsEditing
            nNormId={oNorm.norm_nid}
            bIsModal
            onSuccess={(cMsg) => {
              this.msgSuccess(cMsg);
            }}
            closeModal={this.hideAlert}
            
          />
        </SweetAlert>
      )
    });
  };

  handleClickOutside(evt) {
    if (this.wrapperRef && !this.wrapperRef.contains(evt.target)) {
      this.setState({
        "bActualMenuFolder": false
      });
    }
  }

  render() {
    const { aRequisites, bLoading, aPages, bActualMenuFolder, rcmpAlert, nNormId, oNorm, aFiltredRequisites } = this.state;

    return (
      !bLoading && (
        <div className="centered requisites">
          {rcmpAlert}
          <div ref={this.setWrapperRef} className="title-menu">
            <h1>
              {oNorm.norm_cname} - {oNorm.norm_cinitials}
            </h1>

            <div className="right-menu">
              <span onKeyPress={() => this.menuOpen()} onClick={() => this.menuOpen()} className="btn-menu">
                <span />
              </span>

              <ul style={{ display: bActualMenuFolder ? 'block' : 'none' }} className="submenu">
                <li>
                  <a onClick={(evt) => this.openNormEdit(evt, oNorm)} href="#editar-norma">
                    Editar norma
                  </a>
                </li>

                <li>
                  <a onClick={(evt) => this.showDeleteNorm(evt, nNormId)} style={{ color: '#c66' }} href="#excluir-norma">
                    Excluir
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="lastOption" id="table-emule">
            <div className="head-table">
              <ul>
                <li>Requisitos</li>
              </ul>
            </div>

          <div className="body-table">
              <SortableList
                useDragHandle
                helperClass="tab"
                helperContainer={this.containerEl}
                transitionDuration={0}
                distance={10}
                onSortEnd={this.onSortEnd}
              >
                <ul ref={el => (this.containerEl = el)}>
                  {(aFiltredRequisites.length ? aFiltredRequisites : aRequisites).map((oRequisite, i) => (
                    <SortableItem
                      value={oRequisite.req_nid}
                      helperClass={'selected'}
                      key={`dragItemF-${i}-${oRequisite.req_nid}`}
                      index={i}
                    >
                      <li
                        itemID={oRequisite.req_nid}
                        className={oRequisite.open ? 'open' : ''}
                        key={`req${oRequisite.req_nid}`}
                        role="button"
                        tabIndex="0"
                        onKeyPress={evt => {
                          evt.stopPropagation();
                          this.loadChild(evt, oRequisite);
                        }}
                        onClick={evt => {
                          evt.stopPropagation();
                          this.loadChild(evt, oRequisite);
                        }}
                      >
                        <DragHandle visibility={aFiltredRequisites.length === 0} />

                        {this.renderRequisiteTitleDescription(oRequisite)}

                        <div className="optionsContainerTable">
                          <span className="optionsHover mobile480-hide">{this.renderRequisiteMenu(oRequisite)}</span>
                        </div>

                        {this.renderChilds(oRequisite)}
                      </li>
                    </SortableItem>
                  ))}
              </ul>
              
              </SortableList>
            </div>
          
          </div>
          
          <div className="buttonStack ">
            <button type="button" onClick={(evt) => {
              this.openRequisites(evt)
            }} 
            className="btn primary no-left-margin">
              Novo requisito
            </button>
          </div>

        </div>
      )
    );
  }
}

export default RequisiteIndex;
