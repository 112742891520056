import React, { Component } from 'react';
import { convertToRaw, EditorState, ContentState, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import { Button } from 'reactstrap';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-decoupled-document';
import Axios from '../../config/Axios';
import * as Url from '../../config/Url';
import AltCheckbox from '../../components/AltCheckbox';

export class Term extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      "bCreate": false,
      "oTerm": {}
    };
  }

  onEditorStateChange = (evt, editor) => {
    const { oTerm } = this.state;
    oTerm.adct_ctext = editor.getData();
    this.setState({
      oTerm: oTerm,
      oContentEditor: editor.getData()
    });
  };

  updateTerm = (evt) => {
    evt.preventDefault();
    const { setAlert, history } = this.props;

    Axios.put(`${Url.default.oBase.cUrl + Url.default.oTerm.cUrl}/${this.state.oTerm.adct_nid}`, {
      "adct_ctitle": this.state.oTerm.adct_ctitle,
      "adct_ctext": this.state.oContentEditor,
      "adct_bispublished": this.state.oTerm.adct_bispublished
    })
    .then(() => {
      history.push({
        pathname: '/Term',
        state: { aAlertMessages: { cType: 'success', cMsg: 'Termo de adesão atualizado com sucesso.' } }
      });
    })
    .catch((oError) => {
      history.push({
        pathname: '/Term',
        state: { aAlertMessages: { cType: 'error', cMsg: 'Erro ao atualizar o termo de adesão.' } }
      });
    });
  };

  createTerm = (evt) => {
    evt.preventDefault();
    const { setAlert, history } = this.props;
    if (this.state.oTerm.adct_ctitle === '' || this.state.oContentEditor === '<p></p>') {
      alert('Não é possivel salvar um módulo com titulo e conteúdo vazios.');
      return;
    }

    Axios.post(Url.default.oBase.cUrl + Url.default.oTerm.cUrl, {
      "adct_ctitle": this.state.oTerm.adct_ctitle,
      "adct_ctext": this.state.oContentEditor,
      "adct_bispublished": this.state.oTerm.adct_bispublished
    })
      .then(() => {
        history.push({
          pathname: '/Term',
          state: { aAlertMessages: { cType: 'success', cMsg: 'Termo de adesão criado com sucesso.' } }
        });
      })
      .catch((oError) => {
        history.push({
          pathname: '/Term',
          state: { aAlertMessages: { cType: 'error', cMsg: 'Erro ao criar o termo de adesão.' } }
        });
      });
  };

  inputChangeHandler = (evt) => {
    const { oTerm }= this.state;

    if (evt.target.type === 'checkbox') {
      oTerm[evt.target.name] = evt.target.checked;
      this.setState({
        oTerm
      });
    } else if (evt.target.value !== null) {
      oTerm[evt.target.name] = evt.target.value;
      this.setState({
        oTerm
      });
    }
  };

  componentDidMount = () => {
    const nAdctId = this.props.match.params.nAdctId;
    const { setAlert } = this.props;

    if (nAdctId) {
      Axios.get(`${Url.default.oBase.cUrl + Url.default.oTerm.cUrl}/${nAdctId}`)
        .then((oRes) => {
          const { oAdhesionContract } = oRes.data;

          this.setState({
            oTerm: oAdhesionContract,
            "bCreate": false,
            "oContentEditor": oAdhesionContract.adct_ctext || '<p></p>'
          });
        })
        .catch((oErr) => {
          setAlert('error','Erro ao solicitar o termo de adesão.');
        });
    } else {
      this.setState({
        "oContentEditor": '' || '<p></p>',
        "bCreate": true
      });
    }
  };

  render() {
    const { oTerm, oContentEditor, bCreate } = this.state;

    return (
      <div className="centered">
        <h1>Termo de adesão</h1>

        <header id="inactive-header">
          <form className="form">
            <label className="split">
              Título
              <input
                maxLength="255"
                name="adct_ctitle"
                type="text"
                value={oTerm.adct_ctitle || ''}
                onChange={(evt) => this.inputChangeHandler.call(this, evt)}
              />
            </label>
           
           <div>
              <div className="document-editor__toolbar requisite-editor" />
              <article id="editor-view" className="document-editor__editable-container requisite-editor" />
              <CKEditor
                onInit={ editor => {
                    // Insert the toolbar before the editable area.
                    document.querySelector('.document-editor__toolbar').appendChild(editor.ui.view.toolbar.element);
                    document
                      .querySelector('.document-editor__editable-container')
                      .appendChild(editor.ui.view.editable.element);
                } }
                onChange={this.onEditorStateChange}
                editor={ DecoupledEditor }
                data={oContentEditor || '<p></p>'}
              />
          </div>
            <AltCheckbox
              name="adct_bispublished"
              id="adct_bispublished"
              label="Termo de adesão está publicado"
              onChange={(evt) => this.inputChangeHandler(evt)}
              checked={!!oTerm.adct_bispublished}
            />

            <div className="button-stack">
              <Button type="Button" onClick={() => window.history.back()} className="btn alternate alert">
                Cancelar
              </Button>
              {bCreate === true && (
                <Button type="submit" className="btn" onClick={(evt) => this.createTerm(evt)}>
                  Criar
                </Button>
              )}
              {bCreate === false && (
                <Button type="submit" className="btn" onClick={(evt) => this.updateTerm(evt)}>
                  Salvar
                </Button>
              )}
            </div>
          </form>
        </header>
      </div>
    );
  }
}

export default Term;
