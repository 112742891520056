import React, { Component } from 'react';
import PropTypes from 'prop-types';

/**
 * Component that alerts if you click outside of it
 */
export default class OutsideHandlerClick extends Component {
  constructor(oProps) {
    super(oProps);

    this.setWrapperRef = this.setWrapperRef.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }

  componentDidMount() {
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
  }

  /**
   * Set the wrapper ref
   */
  setWrapperRef(cNode) {
    this.wrapperRef = cNode;
  }

  /**
   * Alert if clicked on outside of element
   */
  handleClickOutside(evt) {
    const { handleClickOutside } = this.props;
    if (this.wrapperRef && !this.wrapperRef.contains(evt.target)) {
      if (handleClickOutside) {
        handleClickOutside(evt, this.wrapperRef);
      }
    }
  }

  render() {
    const { children } = this.props;
    return <div ref={this.setWrapperRef}>{children}</div>;
  }
}

OutsideHandlerClick.propTypes = {
  children: PropTypes.element.isRequired
};
