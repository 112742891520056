import React, { Component } from 'react';
import imgfLoading from '../../assets/img/preloader-black.gif';

// eslint-disable-next-line react/prefer-stateless-function
class PageClean extends Component {

  render () {
    const { bLoading, children, bNotcentered, cClassName } = this.props;

    return (
      <div className={`${bNotcentered === 'true' ? '' : 'centered'} ${cClassName}`}>
        {bLoading ? (
          <div className="centered-text">
            <img alt='Carregando' src={imgfLoading} />
          </div>
        ) : (
          children
        )}
      </div>
    );
  }

}

PageClean.defaultProps = {
  bLoading: false,
  bNotcentered: false,
  cClassName: ''
};

export default PageClean;
