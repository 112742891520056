import React, { Component } from 'react';
import { Button, Modal } from 'react-bootstrap';
import PropTypes from 'prop-types';

class ModalComponent extends Component {
  constructor(oProps){
    super(oProps);
    this.state = {
      bShow: true
    };
  }

  static propTypes = {
    cSize: PropTypes.string
  };

  static defaultProps = {
    cSize: "lg"
  };

  handleClose = () => {
    this.setState({
      'bShow': false
    });
    this.props.onClose();
  }

  handleSave = () => {
    this.setState({
      'bShow': false
    });
    this.props.onConfirm();
  }

  render() {
    return (
      <>
        <Modal size={this.props.cSize}  show={this.state.bShow} onHide={this.handleClose} backdrop={this.props.bBackDrop}>
          <Modal.Header closeButton>
            <Modal.Title>{this.props.cTitle}</Modal.Title>
          </Modal.Header>

          <Modal.Body>{this.props.children}</Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={this.handleClose}>
              {this.props.cCloseTitle}
            </Button>
            <Button variant="primary" onClick={this.handleSave}>
              {this.props.cSaveTitle}
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    );
  }
}

export default ModalComponent;
