import React, { Component } from 'react';
import imgfNoPic from '../../assets/img/nopic.png';
import axios from 'axios';
import SweetAlert from 'react-bootstrap-sweetalert';
import ModalComponent from '../../components/ModalComponent';
import { Form } from 'react-bootstrap';
import { Table } from 'reactstrap';
import User from './User';

class UserIndex extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      aAdminUsers: [],
      bIsForLoading: false,
      oAdminUserUpdate: {
      },
      rcmpAlert: null
    };
  }

  componentDidMount = () => {
    this.getInitData();
  }

  getInitData = () => {
    const { setAlert } = this.props;
    axios.get('admin')
      .then((oResponse) => {
        if (oResponse.data.aAdminUser.length > 0) {
          this.setState({
            aAdminUsers: oResponse.data.aAdminUser,
            "bIsForLoading": true
          });
        }
        else {
          this.setState({ "bIsForLoading": false });
          setAlert('warning', "Não existem usuários!");
        }
      })
      .catch((oError) => {
        this.setState({ "bIsForLoading": false });
        setAlert('error', "Erro ao listar os usuários!");
      });
  }

  handleChange = (evt, cAttributeName) => {
    const { oAdminUserUpdate } = this.state;
    oAdminUserUpdate[cAttributeName] = evt.target.value;
    this.setState({
      oAdminUserUpdate: oAdminUserUpdate
    });
  }

  onUpdate = (oAdminUserUpdate) => {
    this.setState({
      oAdminUserUpdate: oAdminUserUpdate
    });
  }

  showModal = (evt, oAdminUer) => {
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert customClass="modal-edit md" title="" onConfirm={this.hideAlert} showConfirm={false}>
          <div className="head-modal">
            Alterar usuário
            <span role="button" tabIndex="0" onKeyPress={this.hideAlert} onClick={this.hideAlert} className="close" />
          </div>
          <User
            bIsEditing
            bIsModal
            nUserId={oAdminUer.adm_nid}
            fnHandleClose={this.hideAlert}
            fnHandleConfirm={(cType, cMsg) => {
              this.hideAlert();
              this.getInitData();
              setAlert(cType, cMsg);
            }}
          />
        </SweetAlert>
      )
    });
  }

  userDelete = (oAdminUser) => {
    const { setAlert } = this.props;
    axios.delete(`/admin/delete/${oAdminUser.adm_nid}`)
      .then((oResponse) => {
        const aAdminUsers = this.state.aAdminUsers.filter(oItem => oItem.adm_nid !== oAdminUser.adm_nid);
        this.setState({ aAdminUsers: aAdminUsers });
        setAlert('success', 'Usuário excluído com sucesso!');
      })
      .catch((oError) => {
        setAlert('error', 'Erro ao excluir o usuário!');
      });
    this.hideAlert();
  }

  hideAlert = () => {
    this.setState({
      "rcmpAlert": null
    });
  }

  showDelete = (evt, oAdminUser) => {
    this.setState({
      "rcmpAlert": (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={"Confirma"}
          cancelBtnText={"Cancelar"}
          cancelBtnBsStyle="danger"
          title={"Remover usuário"}
          onConfirm={() => this.userDelete(oAdminUser)}
          onCancel={this.hideAlert}
        >
          <p>Deseja realmente excluir o usuário: {oAdminUser.adm_cname}</p>
        </SweetAlert>
      )
    });
  }

  renderListUser = () => {
    const { aAdminUsers } = this.state;
    const { setAlert } = this.props;

    return (
      <Table hover responsive striped>
        <thead>
          <tr>
            <th>#</th>
            <th>Nome</th>
            <th>E-mail</th>
            <th>Status</th>
            <th>Ação</th>
          </tr>
        </thead>
        <tbody>
          {aAdminUsers.map((oAdmin, key) => (
            <tr key={oAdmin.adm_nid}>
              <td>{oAdmin.adm_nid}</td>
              <td>{oAdmin.adm_cname}</td>
              <td>{oAdmin.adm_cemail}</td>
              <td>{oAdmin.adm_bisactive ? 'Ativado' : 'Desativado'}</td>
              <td>
                <a className="rounded-btn edit pointer" title="Visualizar informações ou editar" onClick={(evt) => { this.showModal(evt, oAdmin) }}>
                  <i className="icon-edit" />
                </a>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    );

  }

  render() {
    const { rcmpAlert, bIsForLoading } = this.state;

    return (
      <div className="centered">
        {rcmpAlert}
        <div className="title-button">
          <h1>Gerenciar usuários</h1>
          <div className="button-stack">
            <a href="#/User/Create" title="" className="btn alternate">
              Criar usuário
            </a>
          </div>
        </div>
        {bIsForLoading ? this.renderListUser() : ''}
      </div>
    );
  }
}

export default UserIndex;
