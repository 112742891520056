import React from 'react';

export class AltCheckbox extends React.Component {
  constructor(oProps) {
    super(oProps);
  }

  static defaultProps = {
    "title": 'Clique para alterar o status',
  };

  render() {
    const { className, title, checked, value, name, id, label, message, onChange } = this.props;

    return (
      <label className={`alt-checkbox ${className}`} onChange={onChange}>
        <span title={title} className={`togglecheck ${checked ? 'selected' : ''}`} />
        <input
          checked={checked}
          value={value}
          name={name}
          type="checkbox"
          id={id}
          onChange={() => {}}
        />
        {label}
        <p>{message}</p>
      </label>
    );
  }
}

export default AltCheckbox;

AltCheckbox.defaultProps = {
  className: 'split2',
  message: null
};
