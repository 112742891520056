import React, { Component } from 'react';
import { Table } from 'reactstrap';
import Axios from '../../config/Axios';
import imgfLoading from '../../assets/img/preloader-black.gif';
import BoardModelStatus from './BoardModelStatus';

class BoardModelndex extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      aBoardModels: [],
      bIsLoading: false,
      rcmpAlert: null
    };
  }

  componentDidMount = () => {
    this.getInitData();
  };

  getInitData = () => {
    this.setState({ bIsLoading: true });
    const { setAlert } = this.props;
    Axios.get('/operational-panel-models')
      .then(oResponse => {
        this.setState({
          aBoardModels: oResponse.data,
          bIsLoading: false
        });
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
        this.setState({ bIsLoading: false });
      });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  };

  render() {
    const { aBoardModels, rcmpAlert, bIsLoading } = this.state;
    return (
      <>
        {rcmpAlert}
        <section id="container" className="centered">
          <div>
            <div className="title-button">
              <h1>Modelo de Quadros</h1>
            </div>

            {bIsLoading ? (
              <div className="center-loading-image">
                <img className="text-center" alt="Carregando" src={imgfLoading} />
              </div>
            ) : (
              <Table hover responsive striped>
                <thead>
                  <tr>
                    <th>Nome</th>
                    <th>Descrição</th>
                    <th className="text-center">Ativo?</th>
                    <th className="text-center">Criado por:</th>
                    <th className="text-center">Downloads</th>
                  </tr>
                </thead>
                <tbody>
                  {aBoardModels.map(oBoardModel => (
                    <tr key={`board-model-${oBoardModel.opmd_nid}`}>
                      <td title={oBoardModel.opmd_cname}>
                        <span className="abbreviate-text">{oBoardModel.opmd_cname}</span>
                      </td>
                      <td
                        className="abbreviate-text"
                        title={oBoardModel.opmd_cdescription}
                        style={{ maxWidth: '550px' }}
                      >
                        <span>{oBoardModel.opmd_cdescription}</span>
                      </td>
                      <td className="text-center">
                        <BoardModelStatus
                          setAlert={this.props.setAlert}
                          oBoardModel={oBoardModel}
                          aBoardModels={aBoardModels}
                        />
                      </td>
                      <td className="text-center abbreviate-text">
                        {oBoardModel.opmd_user ? oBoardModel.opmd_user.cname : null}
                      </td>
                      <td className="text-center">{oBoardModel.opmd_downloads_ncount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            )}
          </div>
        </section>
      </>
    );
  }
}

export default BoardModelndex;
