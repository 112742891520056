import React, { Component } from 'react';
import { Table } from 'reactstrap';
import { NavLink, HashRouter } from 'react-router-dom';
import * as Url from '../../config/Url';
import Axios from '../../config/Axios';
import ModalComponent from '../../components/ModalComponent';
import Customer from './Customer';
import SweetAlert from 'react-bootstrap-sweetalert';
import moment from 'moment';

class CustomerIndex extends Component {
  constructor(oProps) {
    super(oProps);
    this.state = {
      aCustomers: [],
      rcmpAlert: null,
      oCustomer: null
    };
  }

  componentDidMount = () => {
    this.getInitData();
  };

  getInitData = () => {
    const { setAlert } = this.props;
    Axios.get('/customer')
      .then(oResponse => {
        this.setState({
          "aCustomers": oResponse.data
        });
      })
      .catch((oError) => {
        setAlert('error', oError.aMsgErrors);
      });
  };

  hideAlert = () => {
    this.setState({
      rcmpAlert: null
    });
  }

  updateCustomer = (evt) => {
    const { oCustomer } = this.state;
    const { setAlert } = this.props;

    Axios.put(`customer/${oCustomer.cust_nid}`, {
      oCustomer
    })
      .then(oResponse => {
        setAlert('success', oResponse.data.success);
        this.hideAlert();
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
        this.hideAlert();
      });
  }

  onUpdate = (oCustItem) => {
    this.setState({
      oCustomer: oCustItem
    });
  }

  showEditCustomer = (evt, oCustItem) => {
    evt.preventDefault();
    const { setAlert } = this.props;

    this.setState({
      rcmpAlert: (
        <SweetAlert customClass="modal-edit lg" title="" onConfirm={this.hideAlert} showConfirm={false}>
          <div className="head-modal">
            Editar cliente
            <span role="button" tabIndex="0" onKeyPress={this.hideAlert} onClick={this.hideAlert} className="close"/>
          </div>
          <Customer 
            bIsEditing 
            bIsModal
            nCustId={oCustItem.cust_nid} 
            fnHandleClose={this.hideAlert} 
            fnHandleConfirm={(cType, cMsg) => {
              this.hideAlert();
              this.getInitData();
              setAlert(cType, cMsg);
            }}
          />
        </SweetAlert>
      )
    });
  }

  disableCustomer = (evt, oCustIDisable) => {
    const { setAlert } = this.props;
    Axios.put(`customer/disable/${oCustIDisable.cust_nid}`)
      .then(oResponse => {
        setAlert('success', oResponse.data);
        this.hideAlert();
        this.componentDidMount();
      })
      .catch(oError => {
        setAlert('error', oError.aMsgErrors);
        this.hideAlert();
      });
  }

  showAlertDisable = (evt, oCustIDisable) => {
    this.setState({
      rcmpAlert: (
        <SweetAlert
          danger
          showCancel
          confirmBtnText={"Confirma"}
          cancelBtnText={"Cancelar"}
          cancelBtnBsStyle="danger"
          title={"Desativar cliente"}
          onConfirm={(evt) => { this.disableCustomer(evt, oCustIDisable) }}
          onCancel={this.hideAlert}
        >
          <p>Deseja realmente desativar o cliente: {oCustIDisable.cust_cname}</p>
        </SweetAlert>
      )
    });
  }

  render() {
    const { aCustomers, rcmpAlert } = this.state;
    return (
      <>
        {rcmpAlert}
        <div>
          <div className="title-button">
            <h1>Lista de Clientes</h1>

            <div className="button-stack">
              <HashRouter>
                <NavLink className="btn alternate" to="/Customer/Create" title="Cliente">
                  Criar cliente
                </NavLink>
              </HashRouter>
            </div>
          </div>

          <Table hover responsive striped>
            <thead>
              <tr>
                <th>#</th>
                <th>Nome</th>
                <th>Ativo</th>
                <th>Status</th>
                <th>Espaço utilizado(%)</th>
                <th>Voucher</th>
                <th>Data de criação</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              {aCustomers.map((oCustomer, i) => (
                <tr key={oCustomer.cust_nid}>
                  <td>{oCustomer.cust_nid}</td>
                  <td title={oCustomer.cust_cname}>
                    <span className="container-config abbreviate-text">
                      {oCustomer.cust_cname}
                    </span>
                  </td>
                  <td>{oCustomer.cust_bisactive ? 'Sim' : 'Não'}</td>
                  <td>{oCustomer.status}</td>
                  <td>{oCustomer.cust_nusedspace}</td>
                  <td>{oCustomer.cust_csellervoucher}</td>
                  <td>
                    {moment.parseZone(oCustomer.cust_dcreated).format('DD/MM/YYYY H:mm')}
                  </td>
                  <td>
                    <a className="rounded-btn edit pointer" title="Visualizar informações ou editar" onClick={(evt) => { this.showEditCustomer(evt, oCustomer) }}>
                      <i className="icon-edit" />
                    </a>
                    &nbsp;&nbsp;&nbsp;
                    {oCustomer.cust_bisactive ?
                      (<a className="rounded-btn edit pointer" title="Desativar" onClick={(evt) => { this.showAlertDisable(evt, oCustomer) }}>
                        <i className="icon-close" />
                      </a>)
                      : ''
                    }
                  </td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default CustomerIndex;
