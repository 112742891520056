import React, { Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import Login from '../login/Login';

class RouterExternal extends Component {
  render() {
    return (
      <HashRouter>
        <Switch>
          <Route exact path="/" component={Login} />
          <Route exact path="/login" component={Login} />
          <Route component={Login} />
        </Switch>
      </HashRouter>
    );
  }
}

export default RouterExternal;
