import React, { Component } from 'react';

export class OptionMenu extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      "bShowMenu": false
    };
  }

  componentDidMount() {
    document.addEventListener('click', this.handleClickOutside, false);
  }

  handleClickOutside = (evt) => {
    if (evt.target !== this.span && evt.target !== this.spanLink) {
      this.setState({
        "bShowMenu": false
      });
    }
  };

  menuOpen = (evt) => {
    evt.preventDefault();
    this.setState((oCurrentState) => ({
      "bShowMenu": !oCurrentState.bShowMenu
    }));
  };

  render() {
    const { bShowMenu } = this.state;
    const { children } = this.props;
    return (
      <div className="right-menu">
        <span
          onKeyPress={(evt) => this.menuOpen(evt)}
          onClick={(evt) => this.menuOpen(evt)}
          className="btn-menu"
          role="button"
          tabIndex={0}
          ref={(cSpanLink) => {
            this.spanLink = cSpanLink;
          }}
        >
          <span
            className="insidespan"
            ref={(cSpan) => {
              this.span = cSpan;
            }}
          />
        </span>

        <ul style={{ display: bShowMenu ? 'block' : 'none' }} className="submenu">
          {children}
        </ul>
      </div>
    );
  }
}

export default OptionMenu;
