import React, { Component } from 'react';

import { NavLink, HashRouter } from 'react-router-dom';

import imgfLogo from '../../assets/img/logo.png';
import imgfNoPic from '../../assets/img/nopic.png';
import OutsideHandlerClick from '../../components/OutsideHandlerClick';
import axios from 'axios';

class Header extends Component {
  constructor(oProps) {
    super(oProps);

    this.state = {
      oAdminUser: {
        adm_cemail: "",
        adm_cname: "Uniware"
      }
    };
  }
  componentDidMount() {
    let nUserId = localStorage.getItem('nUserId');
    axios.get(`admin/${nUserId}`)
      .then(oResponse => {
        this.setState({
          oAdminUser: oResponse.data.oAdminUser
        });
      })
      .catch(oError => {
        
      });
  }
  
  openMenu = () => {
    document.getElementById('main-menu').classList.toggle('opened');
    document.getElementById('menu-button').classList.toggle('active');
  };

  openPopUpConfig = () => {
    document.getElementById('user-menu').style.display =
      document.getElementById('user-menu').style.display === 'none' ? 'block' : 'none';
  };

  toogleUsermenu = display => {
    document.getElementById('user-menu').style.display = display;
  };

  doLogout = () => {
    localStorage.setItem('cAccessToken', '');
    window.location.reload();
  };

  render() {
    const { oAdminUser } = this.state;
    
    return (
      <>
        <header id="header">
          {/* eslint-disable-next-line */}
          <a onClick={this.openMenu} id="menu-button">
            <span />
          </a>
          <HashRouter>
            <NavLink to="/" title="Acredite.se" id="logo">
              <img src={imgfLogo} className="App-logo" alt="Acredite.se" />
            </NavLink>
          </HashRouter>
          {/* eslint-disable-next-line */}
          <a onClick={this.openPopUpConfig} id="user-tip">
            <img src={imgfNoPic} alt="" />
          </a>
          {/* eslint-disable-next-line */}
          {/* <a title="#" id="notifications-tip">
            <span />
          </a> */}
        </header>

        <OutsideHandlerClick handleClickOutside={() => this.toogleUsermenu('none')}>
          <nav className="animated fadeIn" style={{ display: 'none' }} id="user-menu">
            <div id="user-card">
              <div id="user-avatar">
                <img src={imgfNoPic} alt="Uniware" />
              </div>
              <div id="user-info">
                <h2 title={oAdminUser.adm_cname}>{oAdminUser.adm_cname}</h2>
              </div>
            </div>
            <ul>
              <li>
                <a href="/#" title="Sair" onClick={this.doLogout}>
                  Sair
                </a>
              </li>
            </ul>
          </nav>
        </OutsideHandlerClick>
      </>
    );
  }
}

export default Header;
