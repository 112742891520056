import React, { Component } from 'react';
import { AltCheckbox } from '../../components/AltCheckbox';
import { Alert } from '../../components/Alert';
import axios from 'axios';
import InputPassword from '../../components/InputPassword';
import PropTypes from 'prop-types';
import PageClean from '../default/PageClean';

class User extends Component {
  static propTypes = {
    bIsEditing: PropTypes.bool,
    bIsModal: PropTypes.bool,
    fnHandleClose: PropTypes.func,
    fnHandleConfirm: PropTypes.func,
  };

  static defaultProps = {
    bIsEditing: false,
    bIsModal: false,
    fnHandleClose: evtHandleClose => { },
    fnHandleConfirm: evtHandleConfirm => { },
  };

  constructor(oProps) {
    super(oProps);
    this.state = {
      oAdminUser: {
        adm_cname: '',
        adm_bisactive: false,
        adm_cemail: '',
        adm_cpassword: '',
        adm_cconfirmpassword: ''
      },
      bLoading: true,
      rcmpAlertMsg: null
    };
  }

  componentDidMount() {
    const { bIsEditing, nUserId, setAlert, onClose, onUpdate } = this.props;

    if (bIsEditing) {
      axios.get(`admin/${nUserId}`)
        .then(oResponse => {
          this.setState({
            oAdminUser: oResponse.data.oAdminUser,
            bLoading: false
          });
        })
        .catch(oError => {
          this.setState({
            rcmpAlertMsg: (
              <Alert type="error" isOpen onCloseAlert={() => { }}>
                {oError.aMsgErrors}
              </Alert>
            )
          });
        });
    }

  }

  handleChange = (evt, cAttributeName) => {
    const { oAdminUser } = this.state;

    if (evt.target.type === 'checkbox') {
      oAdminUser[cAttributeName] = evt.target.checked;
      this.setState({
        oAdminUser
      });
      if (this.props.onUpdate) this.props.onUpdate(oAdminUser);
    } else if (evt.target.value !== null) {
      oAdminUser[cAttributeName] = evt.target.value;
      this.setState({
        oAdminUser
      });
      if (this.props.onUpdate) this.props.onUpdate(oAdminUser);
    }
  }

  userCreate = () => {
    const { history } = this.props;
    const { oAdminUser } = this.state;

    axios.post('/admin/store', {
      'adm_cname': oAdminUser.adm_cname,
      'adm_cemail': oAdminUser.adm_cemail,
      'adm_cpassword': oAdminUser.adm_cpassword,
      'adm_bisactive': oAdminUser.adm_bisactive
    })
      .then((oResponse) => {
        history.push({
          pathname: '/User',
          state: { aAlertMessages: { cType: 'success', cMsg: 'Usuário criado com sucesso!' } }
        });
      }).catch((oError) => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  }

  userUpdate = () => {
    const { fnHandleConfirm } = this.props;
    const { oAdminUser } = this.state;

    axios.put('/admin/update', {
      'adm_nid': oAdminUser.adm_nid,
      'adm_cname': oAdminUser.adm_cname,
      'adm_cemail': oAdminUser.adm_cemail,
      'adm_cpassword': oAdminUser.adm_cpassword,
      'adm_bisactive': oAdminUser.adm_bisactive,
    })
      .then((oResponse) => {
        fnHandleConfirm('success', 'Usuário atualizado com sucesso!');
      })
      .catch((oError) => {
        this.setState({
          rcmpAlertMsg: (
            <Alert type="error" isOpen onCloseAlert={() => { }}>
              {oError.aMsgErrors}
            </Alert>
          )
        });
      });
  }

  handleSubmit = (evt) => {
    evt.preventDefault();
    const { setAlert, bIsEditing } = this.props;
    const { oAdminUser } = this.state;

    if (oAdminUser.adm_cpassword !== oAdminUser.adm_cconfirmpassword && oAdminUser.adm_cpassword !== undefined) {
      setAlert('error', 'Senhas diferentes!');
    } else if (oAdminUser.adm_cpassword !== undefined && oAdminUser.adm_cpassword.length < 6) {
      setAlert('error', 'Número mínimo de caracteres deve ser maior ou igual a 6!');
    } else if (bIsEditing) {
      this.userUpdate();
    } else {
      this.userCreate();
    }

  }

  renderAdminUser = () => {
    const { oAdminUser } = this.state;
    const { bIsEditing, fnHandleClose, history } = this.props;

    return (
      <form
        className="form"
        onSubmit={this.handleSubmit}
      >
        <input name="_token" type="hidden" value="EvqPygz3uAjVmAdnwafVRC2OEzt0Oyti8qsr1BG9" />
        <fieldset>
          <legend>Informações pessoais</legend>
          <label className="split2">
            Nome Completo
              <input maxLength="250" className="" required name="adm_cname" type="text" value={oAdminUser.adm_cname} onChange={evt => this.handleChange(evt, 'adm_cname')} />
          </label>

          <AltCheckbox
            name="adm_bisactive"
            id="adm_bisactive"
            label="Usuário Ativo"
            onChange={evt => this.handleChange(evt, 'adm_bisactive')}
            checked={oAdminUser.adm_bisactive}
          />
        </fieldset>
        <fieldset>
          <legend>Dados de acesso</legend>
          <label className="split">
            E-mail
              <input
              maxLength="250"
              className=""
              required
              name="adm_cemail"
              type="email"
              value={oAdminUser.adm_cemail || ''}
              onChange={evt => this.handleChange(evt, 'adm_cemail')}
              autoComplete="new-email"
            />
          </label>
          <div className="clearfix" />
          <label className={!bIsEditing ? "split2 password" : "password"}>
            <InputPassword
              value={oAdminUser.adm_cpassword || ''}
              inputid={"adm_cpassword"}
              inputplaceholder={"Senha"}
              inputname={"adm_cpassword"}
              onChange={evt => this.handleChange(evt, 'adm_cpassword')}
              required={!bIsEditing}
            />
          </label>

          <label className={!bIsEditing ? "split2 password" : "password"}>
            <InputPassword
              value={oAdminUser.adm_cconfirmpassword || ''}
              inputid={"adm_cconfirmpassword"}
              inputplaceholder={"Confirmar Senha"}
              inputname={"adm_cconfirmpassword"}
              onChange={evt => this.handleChange(evt, 'adm_cconfirmpassword')}
              required={!bIsEditing}
            />
          </label>

        </fieldset>

        <div className="button-stack">
          <button type="button" className="btn alternate back btn btn-outline-secondary" title="Cancelar" onClick={(evtClick) => {
            if (bIsEditing) {
              fnHandleClose();
            } else {
              history.push({
                pathname: '/User'
              });
            }
          }}>
            Cancelar
            </button>
          {bIsEditing ? (
            <button type="submit" value="salvar" className="btn btn btn-secondary" title="Salvar">
              Salvar
            </button>
          ) : (
            <button type="submit" value="salvar" className="btn btn btn-secondary" title="Criar">
              Criar
            </button>
          )}
        </div>

      </form>
    );
  }

  render() {
    const { bLoading, rcmpAlertMsg } = this.state;
    const { bIsModal } = this.props;

    if (bIsModal) {
      return (
        <PageClean bLoading={bLoading ? 1 : 0}>
          {rcmpAlertMsg}
          {this.renderAdminUser()}
        </PageClean>
      );
    }

    return (
      <>
        <main id="container" className="centered">
          <div className="clearfix" />
          {rcmpAlertMsg}
          <h1>Criar usuário</h1>
          {this.renderAdminUser()}
          <ul id="bubble-alerts" />
        </main>
      </>
    );
  }
}

export default User;
