import React from 'react';
import ReactDOM from 'react-dom';
import axios from 'axios';
import App from '../App';

const Axios = axios.create();

export const extractErrorMessages = oError => {
  const aMsgErrors = [];

  if (typeof oError === 'string') {
    aMsgErrors.push(oError);
  } else if (oError.response && oError.response.status === 429) {
    aMsgErrors.push('O usuário enviou muitas solicitações em um curto período de tempo.');
  } else if (oError.response && oError.response.status >= 400 && oError.response.status < 500) {
    const { cError } = oError.response.data;
    let { errors } = oError.response.data;

    if (typeof errors === 'undefined') {
      errors = oError.response.data.errors;
    }
    if (typeof cError === 'string') {
      errors = [];
      errors.push(cError);
    }

    if (errors === undefined) {
      aMsgErrors.push(oError.response.data);
    } else if (typeof errors === 'string') {
      aMsgErrors.push(errors);
    } else {
      // eslint-disable-next-line no-restricted-syntax
      for (const cField in errors) {
        if (typeof errors[cField] === 'string') {
          aMsgErrors.push(errors[cField]);
        } else if (typeof (errors[cField]) === "object" && typeof (errors[cField].cMessage) === "string") {
          aMsgErrors.push(errors[cField].cMessage);
        } else {
          errors[cField].map(evt => aMsgErrors.push(evt));
        }
      }
    }
  } else {
    aMsgErrors.push('Opa! Estamos temporariamente com problemas.');
  }
  return aMsgErrors;
};



Axios.interceptors.response.use(
  (oResponse) => oResponse,
  (oError) => {
    if (oError.response && oError.response.status === 401) {
      localStorage.removeItem('cAccessToken');
      ReactDOM.unmountComponentAtNode(document.getElementById('root'));
      ReactDOM.render(<App />, document.getElementById('root'));
    } else {
      // eslint-disable-next-line no-param-reassign
      oError.aMsgErrors = extractErrorMessages(oError);

      return Promise.reject(oError);
    }
  }
);

export default Axios;
